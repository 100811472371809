import axios from "axios";

export const setUserSessionAuthKey = () => {
  let localData = localStorage.getItem("rebelSkoolUser");
  let localObject = localData && JSON.parse(localData);
  if (null != localObject) {
    let userSessionAuthKey = localObject["User-Session-Auth-Key"];
    if (userSessionAuthKey != "" && userSessionAuthKey != null) {
      let axiosConfig = {
        headers: {
          "User-Session-Auth-Key": localObject["User-Session-Auth-Key"],
        },
      };
      return axiosConfig;
    } else return false;
  } else return false;
};

export const numberWithCommasAndSpace = (amount) => {
  let amountString = amount.toString();
  if (!amountString.includes(".")) {
    amount = amount.toFixed(2);
  }

  return amount
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

export const previewPdf = (currentReportGUIDFromUrl) => {
  axios
    .get("/annualReport/pdf?reportGUID=" + currentReportGUIDFromUrl, {
      responseType: "blob",
    })
    .then((response) => {
      response["Access-Control-Expose-Headers"] = "Content-Disposition";
      const filename =
        response.headers["content-disposition"].split("filename=")[1];
      const blob = new Blob([response.data], { type: "application/pdf" });
      let url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    });
};
