import React, { Component } from "react";
import Sidebar from "../SRUSidebar/SRUSidebar";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import ScrolltoTop from "../../ScrollTop/ScrollTop";
import axios from "axios";
import { Messages } from "primereact/messages";
import "./SRUReviewAndSend.css";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { setUserSessionAuthKey } from "../../../Component/commonUtils";
import TranslationFile from "../../15-Translation/locale/Translation_apps.json";
import SRUNavBarHeader from "../SRUNavBarHeader/SRUNavBarHeader";
import Footer_contact_tab from "../../Footerpage/Footer_contact_tab";
import config from "../../../Configuration/Config";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails.sessionDetails.values,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    payment: state.payment.payment,
  };
};

let currentReportGUIDFromUrl = "",
  linkSharedUser,
  sideBarGreenTickPages = [];
let userSelectedLanguage;

class SRUReviewAndSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutId: "",
      checkoutPurchaseId: "",
      checkoutAmount: 0,
      showPayementSuccessModal: false,
      showNotification: false,
      loading: true,
      infoFile: [],
      blanketFile: [],
      sideBarStatus: true,
      infoFileText: "",
      currentReportId: "",
    };
    this.acceptSendToSkatteverket = this.acceptSendToSkatteverket.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    const { login, sideBarReducer, payment } = this.props;

    let paysonGetCheckoutObj = {
      userId: login.userID,
      isCheckoutObj: true,
    };

    this.setState({
      showNotification: false,
    });
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    window.scrollTo(0, 0);

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseDate = response.data;

            if (responseDate != "") {
              this.setState({ currentReportId: responseDate.reportID });
              let reportAccessReq = {
                reportId: responseDate.reportID,
              };
              axios
                .post(
                  "/SRUHeader/getAccessStatus",
                  reportAccessReq,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  switch (response.data) {
                    case 2:
                      linkSharedUser = 2; //Write
                      break;
                    case 3:
                      linkSharedUser = 3; //Read Only
                      break;
                    case 4:
                      linkSharedUser = 4; //No Access
                      break;
                  }

                  if (response.data <= 3) {
                    axios
                      .get(
                        "/SRULines/generateSRUFile?reportId=" +
                          responseDate.reportID,
                        userSessionAuthKeyReturnValue
                      )
                      .then((response) => {
                        axios
                          .get(
                            "/SRUHeader/getHeader?reportGuid=" +
                              currentReportGUIDFromUrl,
                            userSessionAuthKeyReturnValue
                          )
                          .then((response) => {
                            let headerResponse = response.data;
                            const allLines = response.data.infoFile.split("\n");
                            const allBlanketLines = response.data.blanketFile.split("\n");
                            this.setState({ infoFile: allLines,blanketFile: allBlanketLines});
                          });
                      });
                    // axios
                    //   .get(
                    //     "/annualReport/pdf?reportGUID=" +
                    //     currentReportGUIDFromUrl,
                    //     {
                    //       responseType: "blob",
                    //     }
                    //   )
                    //   .then((response) => {
                    //     const url = window.URL.createObjectURL(
                    //       new Blob([response.data], { type: "application/pdf" })
                    //     );
                    //     this.setState({
                    //       infoFile: url,
                    //     });
                    //   });
                  }

                  axios
                    .get(
                      "/SRU/getCompletedPages?reportId=" +
                        responseDate.reportID,
                      userSessionAuthKeyReturnValue
                    )
                    .then((response2) => {
                      sideBarReducer.values = response2.data;
      
                      sideBarReducer.values.push("My Dashboard");
                      sideBarReducer.values.push("Company Information");
      
                      let getPossibleAccessablePages = localStorage.getItem("possibleAccessablePages");
                      let getPossibleAccessablePagesArray = JSON.parse(getPossibleAccessablePages);
                      getPossibleAccessablePagesArray.push("Review And Send")
      
                      sideBarReducer.values.push("Review And Send");
                      let updatePossibleNavigationPagesListString = JSON.stringify(
                        getPossibleAccessablePagesArray
                      );
                      localStorage.setItem(
                        "possibleAccessablePages",
                        updatePossibleNavigationPagesListString
                      );
                    });
                })
                .catch((error) => {
                  let noAccessURL =
                    "/income-declaration-2/" +
                    currentReportGUIDFromUrl +
                    "/no-access";
                  this.props.history.push(noAccessURL);
                });
            }
            // }

            axios
              .get(
                "/SRU/getCompletedPages?reportId=" + response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                let sideBarReducerValues = [];
                sideBarReducerValues = completedStatementTypesResponse.data;
                if (linkSharedUser != 3) {
                  sideBarReducerValues.push("Payment");
                }
                sideBarReducer.values = sideBarReducerValues;
                sideBarGreenTickPages = completedStatementTypesResponse.data;
                this.setState({
                  reRender: true,
                });

                setTimeout(() => {
                  if (payment.values.checkoutRef != "") {
                    axios
                      .get(
                        "/annualReportPackage/getCheckoutDetailsByRef?checkoutRef=" +
                          payment.values.checkoutRef,
                        userSessionAuthKeyReturnValue
                      )
                      .then((checkoutRefResponse) => {
                        this.setState({
                          loading: false,
                        });
                        if (
                          checkoutRefResponse.data != "" &&
                          !checkoutRefResponse.data.isNotificationShown
                        ) {
                          this.setState({
                            showNotification: true,
                          });
                          axios
                            .get(
                              "/annualReportPackage/updateNotificationByCheckoutRef?checkoutRef=" +
                                payment.values.checkoutRef,
                              userSessionAuthKeyReturnValue
                            )
                            .then((updateNotifyResponse) => {});
                        }
                      });
                  } else {
                    this.setState({
                      loading: false,
                    });
                  }
                }, 3000);
              });
          }
        });
    }
  }

  componentDidMount() {
    this.msgs1.show([
      {
        severity: "success",
        detail: TranslationFile.Paymentsuccess[userSelectedLanguage],
        sticky: true,
      },
    ]);
  }
  paymentSuccessModalOnHide() {
    this.setState({
      showPayementSuccessModal: false,
    });
  }

  acceptSendToSkatteverket() {
    this.toast.show({
      severity: "success",
      summary: TranslationFile.SumissionSuccess[userSelectedLanguage],
      detail: TranslationFile.PaymentSubmitToBolags[userSelectedLanguage],
      life: 2000,
    });
  }

  next(e) {
    const incomeDeclaration2SRU = "/income-declaration-2/";
    this.props.history.push(
      incomeDeclaration2SRU + currentReportGUIDFromUrl + "/digitalSignature"
    );
  }

  confirmSubmitToSkatteverket(e) {
    confirmPopup({
      target: e.currentTarget,
      message: "Are you sure you want to send this report to Skatteverket",
      icon: "pi pi-check",
      accept: this.acceptSendToSkatteverket.bind(this),
      // reject
    });
  }

  // axios(
  //   "/SRULines/downloadInfoSRUFile?reportID=" + currentReportId,
  //   userSessionAuthKeyReturnValue
  // ).then((response) => {

  // })
  // ;

  downloadInfoSRU = async () => {
    const { currentReportId } = this.state;
    if (userSessionAuthKeyReturnValue) {
      let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = localData && JSON.parse(localData);
      try {
        // const response = await fetch(`/SRULines/downloadInfoSRUFile?reportID=${currentReportId}`);
        const response = await fetch(
          config.backend.url +
          "SRULines/downloadInfoSRUFile?reportID=" +
          currentReportId,
          {
            method: "GET",
            headers: {
              // "Content-Type": "application/pdf",
              "User-Session-Auth-Key": localObject["User-Session-Auth-Key"],
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to download file");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "files.zip"); // Specify the file name
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
      }
    }
  };

  editReport() {
    const incomeDeclaration2SRU = "/income-declaration-2/";
    this.props.history.push(
      incomeDeclaration2SRU + currentReportGUIDFromUrl + "/companyInfo"
    );
    window.location.reload(false);
  }

  sideBarCallback = (value) => {};
  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };
  render() {
    const {
      checkoutId,
      checkoutPurchaseId,
      checkoutAmount,
      showPayementSuccessModal,
      showNotification,
      loading,
      infoFile,blanketFile,
      infoFileText,
    } = this.state;

    return (
      <div className="notes-main-div">
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          style={{ marginTop: "80px" }}
        ></Toast>

        <SRUNavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />

        <div className="paymentSideBar">
          <Sidebar
            children="Review And Send"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={(e) => this.sideBarCallback(e)}
          />
        </div>
        <ConfirmPopup />
        <div
          className="reviewAndSendPaymentSuccess"
          style={{ display: showNotification ? "inherit" : "none" }}
        >
          <Messages ref={(el) => (this.msgs1 = el)} />
        </div>

        <div className="reviewAndSend-div">
          <div className="review-edit">
            {/* <Row>
              <Col xl={10} style={{ textAlign: "center", paddingTop: "3px" }}>
                <h5>
                  Kindly Review the SRU file and send the report to Skatteverket
                </h5>
              </Col>
              <Col>
                <Button
                  label="Edit Report"
                  icon="pi pi-pencil"
                  onClick={() => this.editReport()}
                />
              </Col>
            </Row> */}
          </div>
          <center>
            <div
              style={{ textAlign: "left", marginLeft: "10%", color: "blue" }}
            >
              <Button
                icon="pi pi-download"
                label="Download Files"
                onClick={() => this.downloadInfoSRU()}
              />
            </div>
            <div className="sru-info-div">
              {infoFile &&
                infoFile.map((line, index) => <div key={index}>{line}</div>)}
            </div>
          </center>
          {/* <br></br> */}
          <center>
            <div className="sru-info-div">
              {blanketFile &&
                blanketFile.map((line, index) => <div key={index}>{line}</div>)}
            </div>
            <div className="review-submit">
              <Button
                label="Submit to Skatteverket"
                icon="pi pi-send"
                onClick={(e) => this.confirmSubmitToSkatteverket(e)}
                disabled={linkSharedUser == 2 || linkSharedUser == 3}
              />
            </div>
          </center>
        </div>

        <ScrolltoTop />
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(SRUReviewAndSend);
