import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import BankIdLogo from "../../Assests/BankID_logo.png";
import { Row, Col } from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import { useHistory } from "react-router-dom";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import axios from "axios";
import QRLib from "qrcode";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import moment from "moment";
import "./digitalSignature.css";

let userSelectedLanguage;
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let currentReportGUIDFromUrl = "";
let sideBarGreenTickPages = [];
let tourGuidePages = localStorage.getItem("tourGuidePages");
let currentPageTourGuideName = "Signature";


let steps = [
  // {
  //   content: TranslationFile.TourGuide_Add_NewRep_Btn[userSelectedLanguage],
  //   placement: "top",
  //   styles: {
  //     options: {
  //       width: 300,
  //     },
  //   },
  //   target: "#rep_addRep",
  //   title: TranslationFile.repAddRep[userSelectedLanguage],
  //   disableBeacon: true,
  // },

  // {
  //   content:
  //     TranslationFile.TourGuide_Add_CheckBox_Delete_Btn[userSelectedLanguage],
  //   placement: "top",
  //   styles: {
  //     options: {
  //       width: 300,
  //     },
  //   },
  //   target: ".whole-delete",
  //   title: TranslationFile.repDeleteBtn[userSelectedLanguage],
  //   disableBeacon: true,
  // },
];


export default function Representative() {
  const [qrImage, setQrImage] = useState();
  const [isScanning, setIsScanning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [digitalSignFail, setDigitalSignFail] = useState(false);
  const [digitalSignSuccess, setDigitalSignSuccess] = useState(false);
  const [qrCodeLoading, setQrCodeLoading] = useState(true);
  const [run, setRun] = useState(false);
  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  const history = useHistory();

  const [sideBarStatus, setSideBarStatus] = useState(true);
  const [digitalignatureReps, setDigitalignatureReps] = useState([]);
  const [visibleQrCodeDialogBox, setVisibleQrCodeDialogBox] = useState(false);
  const [reportId, setReportId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [currentFinancialYear, setCurrentFinancialYear] = useState("");
  const toast = useRef(null);

  const [reportHeader, setReportHeader] = useState();
  const [reRender, setReRender] = useState(false);
  const [currentRep, setCurrentRep] = useState("");

  const sideBarReducer = useSelector(
    (state) => state.sideBarReducer.sideBarReducer
  );

  const repRoleOptionsObj = [
    { name: TranslationFile.repRollselect2[userSelectedLanguage], code: "CEO" },
    { name: TranslationFile.repRollselect3[userSelectedLanguage], code: "Auditor" },
    { name: TranslationFile.repRollselect4[userSelectedLanguage], code: "Chairman of the Board" },
    { name: TranslationFile.repRollselect5[userSelectedLanguage], code: "Board member" },
    {
      name: TranslationFile.repRollselect6[userSelectedLanguage],
      code: "Chairman of the board and CEO",
    },
    { name: TranslationFile.repRollselect7[userSelectedLanguage], code: "External CEO" },
    { name: TranslationFile.repRollselect8[userSelectedLanguage], code: "Board deputy" },
    { name: TranslationFile.repRollselect10[userSelectedLanguage], code: "Liquidator" },
  ];

  const [signTypeOptions] = useState(["Paper", "Digital Signature"]);

  const [repRoleOptions] = useState([
    TranslationFile.repRollselect2[userSelectedLanguage],
    TranslationFile.repRollselect3[userSelectedLanguage],
    TranslationFile.repRollselect4[userSelectedLanguage],
    TranslationFile.repRollselect5[userSelectedLanguage],
    TranslationFile.repRollselect6[userSelectedLanguage],
    TranslationFile.repRollselect7[userSelectedLanguage],
    TranslationFile.repRollselect8[userSelectedLanguage],
    TranslationFile.repRollselect10[userSelectedLanguage],
  ]);

  const checkForGreenTick = (dSListParamter) => {
    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );
    let possibleAccessablePagesListLSArray = JSON.parse(
      possibleAccessablePagesListLSString
    );
    // if (
    //   possibleAccessablePagesListLSArray != null &&
    //   dSListParamter.length > 0
    // ) {
    possibleAccessablePagesListLSArray.push(
      "Company Information",
      "Income Statement",
      "Balance Sheet",
      "Notes",
      "Management Statement",
      "Representative",
      "Digital Signature"
    );
    let updatePossibleNavigationPagesListString = JSON.stringify(
      possibleAccessablePagesListLSArray
    );

    localStorage.setItem(
      "possibleAccessablePages",
      updatePossibleNavigationPagesListString
    );
    // }
    setReRender(true);
  };

  useEffect(() => {
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    let tourGuidePages = JSON.parse(localStorage.getItem("tourGuidePages")) || [];

    if (userTourGuide) {
      if (tourGuidePages.includes(currentPageTourGuideName)) {
        setTimeout(() => {
          setRun(false);
        }, 200);
      } else {
        setTimeout(() => {
          setRun(true);
        }, 200);
      }
    }

    // Update tourGuidePages in localStorage
    if (!tourGuidePages.includes(currentPageTourGuideName)) {
      tourGuidePages.push(currentPageTourGuideName);
      localStorage.setItem("tourGuidePages", JSON.stringify(tourGuidePages));
    }
  }, [currentPageTourGuideName]);


  useEffect(() => {
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();



    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((getHeaderResponse) => {
          if (getHeaderResponse.data) {
            setReportHeader(getHeaderResponse.data);
            setReportId(getHeaderResponse.data.reportID);
            setCompanyName(getHeaderResponse.data.companyName);
            setCurrentFinancialYear(getHeaderResponse.data.currentYear);
            axios
              .get(
                "/annualReportRepresentative/getByReportId?reportID=" +
                getHeaderResponse.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((getRepresentativeResponse) => {
                let representativeResponseList = getRepresentativeResponse.data;
                if (
                  representativeResponseList != null &&
                  representativeResponseList != "" &&
                  representativeResponseList.length > 0
                ) {
                  setDigitalignatureReps(representativeResponseList);
                  checkForGreenTick(getHeaderResponse.data);
                }

              });

          }
        });
    }
  }, []);

  const sideBarStatusFnc = (e) => {
    setSideBarStatus(e);
  };

  const tourStatus = (e) => {
    setRun(e);
  };


  const sideBarCallback = (value) => {
    if (value) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const digitalSignBtn = (seletedRepDetails) => {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    renderQRScanner(seletedRepDetails);
    setVisibleQrCodeDialogBox(true);
  };

  const digitalSignatureStatusTemplate = (product) => {
    setCurrentRep(product);
    if (product.signType == 1) {
      if (product.signStatus) {
        return (
          <div>
            <Button
              label="Signed"
              severity="success"
              rounded
              className="dseBtn"
              disabled
            />
          </div>
        );
      } else {
        return (
          <Button
            label="Pending"
            className="dseBtn"
            rounded
            onClick={() => digitalSignBtn(product)}
          />
        );
      }
    }
  };

  const signatureDialogBoxOnHide = () => {
    setCurrentRep("");
    stopScanning();
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReportRepresentative/getByReportId?reportID=" + reportId,
          userSessionAuthKeyReturnValue
        )
        .then((getRepresentativeResponse) => {
          let representativeResponseList = getRepresentativeResponse.data;
          if (
            representativeResponseList != null &&
            representativeResponseList != "" &&
            representativeResponseList.length > 0
          ) {

            setDigitalignatureReps(representativeResponseList);
            checkForGreenTick(getRepresentativeResponse);
          }

        });
    }

    setVisibleQrCodeDialogBox(false);

    setDigitalSignFail(false);
    setDigitalSignSuccess(false);
    setQrCodeLoading(true);
  };

  const navigateToReviewAndSend = () => {
    history.push("reviewAndSend");
  };

  const renderQRScanner = (seletedRepDetails) => {
    setIsScanning(true); // Set scanning state to true
    setQrCodeLoading(true);
    axios.get("/api/start").then((startApiResponse) => {
      axios.defaults.headers.common["X-CSRF-TOKEN"] =
        startApiResponse.data.csrfToken;

      const userVisibleData =
        "# Signature in BankID\n\n Here I digitaly sign for the annual report of " +
        reportHeader.companyName +
        " for the financial year of " +
        reportHeader.currentYear;
      const userVisibleDataFormat = "SIMPLE_MARKDOWN_V1";

      // Call the sign API
      axios
        .post("/api/sign", { userVisibleData, userVisibleDataFormat })
        .then((signApiResponse) => {
          // const maxAttempts = 30;
          const maxAttempts = 15;
          let attempts = 0;
          let isCompleted = false;

          const newIntervalId = setInterval(() => {
            if (attempts >= maxAttempts) {
              clearInterval(newIntervalId); // Stop if max attempts reached
              setIsScanning(false); // Reset scanning state
              setVisibleQrCodeDialogBox(false);
              return;
            }

            if (!isCompleted) {
              axios
                .post("/api/check", signApiResponse.data)
                .then((checkApiResponse) => {
                  attempts++;
                  // Check the response status
                  if (checkApiResponse.data.status === "PENDING") {
                    let qrCode = checkApiResponse.data.qrCode;

                    if (qrCode !== undefined) {
                      QRLib.toDataURL(qrCode).then((url) => {
                        setQrCodeLoading(false);
                        setQrImage(url);
                      });
                    }
                  } else if (checkApiResponse.data.status === "COMPLETE") {
                    clearInterval(newIntervalId);
                    isCompleted = true;
                    setIsScanning(false); // Reset scanning state

                    const hiffanRemovedFromSSN = seletedRepDetails.ssn.replace(
                      /-/g,
                      ""
                    );

                    if (
                      checkApiResponse.data.completionResponse.personalNumber ==
                      hiffanRemovedFromSSN
                    ) {
                      setDigitalSignFail(false);
                      setDigitalSignSuccess(true);

                      const currentEpochTime = Math.floor(Date.now() / 1000);
                      setCurrentRep("");
                      axios
                        .get(
                          "/annualReportRepresentative/updateDigitalSignatureStatus?no=" +
                          seletedRepDetails.no +
                          "&signedDateTime=" +
                          currentEpochTime +
                          "&reportId=" +
                          seletedRepDetails.reportID
                        )
                        .then((getHeaderResponse) => { });
                    } else {
                      setDigitalSignFail(true);
                      setDigitalSignSuccess(false);
                    }

                    // setVisibleQrCodeDialogBox(false);
                    stopScanning();
                  }
                })
                .catch((error) => {
                  clearInterval(newIntervalId); // Stop polling on error
                  setIsScanning(false); // Reset scanning state
                });
            }
          }, 2000); // Adjust the interval time as needed

          setIntervalId(newIntervalId); // Save the interval ID for later use
        })
        .catch((error) => {
        });
    });
  };

  const stopScanning = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIsScanning(false); // Reset scanning state
      setQrImage("");
    }
  };

  const onRowEditComplete = (rowData, fieldName) => {
    if (userSessionAuthKeyReturnValue) {
      let updateRepToDB = {
        firstName: rowData.firstName,
        lastName: rowData.lastName,
        sSN: rowData.sSN,
        role: rowData.role,
        reportID: reportId,
        no: rowData.no,
        signType: rowData.signType == "Paper" || rowData.signType == 0 ? 0 : 1,
      };

      axios
        .post(
          "/annualReportRepresentative/update",
          updateRepToDB,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessfulText[userSelectedLanguage],
              detail: TranslationFile.RepUpdateSucess[userSelectedLanguage],
              life: 3000,
            });
            axios
              .get(
                "/annualReportRepresentative/getByReportId?reportID=" +
                reportId,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let representativeResponseList = response.data;
                if (
                  representativeResponseList != null &&
                  representativeResponseList != "" &&
                  representativeResponseList.length > 0
                ) {

                  setDigitalignatureReps(representativeResponseList);
                  checkForGreenTick(response.data);
                }

              });
          }

          if (
            document.getElementsByClassName("p-row-editor-save p-link")[0] !=
            undefined
          ) {
            document
              .getElementsByClassName("p-row-editor-save p-link")[0]
              .click();
          }
        });
    }
  };

  const representativeInputOnBlur = (e, field, rowData) => {
    let getRepResponse = "";
    if (userSessionAuthKeyReturnValue) {
      if (field == "SSN" && rowData.signType == 1 && e.target.value == "") {
        toast.current.show({
          severity: "error",
          summary: TranslationFile.errorText[userSelectedLanguage],
          detail: (
            <>
              {TranslationFile.repToast6[userSelectedLanguage]} <b> {TranslationFile.repDigitalSignature[userSelectedLanguage]}</b> {TranslationFile.signTypeText[userSelectedLanguage]}
            </>
          ),

          life: 6000,
        });
      } else {
        axios
          .get(
            "/annualReportRepresentative/getByReportId?reportID=" + reportId,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            getRepResponse = response.data;
            getRepResponse =
              getRepResponse &&
              getRepResponse.filter((res) => res.no == rowData.no);

            if (getRepResponse[0].firstName !== rowData.firstName) {
              onRowEditComplete(rowData, "firstName");
            } else if (getRepResponse[0].lastName !== rowData.lastName) {
              onRowEditComplete(rowData, "lastName");
            } else if (getRepResponse[0].sSN !== rowData.sSN) {
              onRowEditComplete(rowData, "sSN");
            } else if (getRepResponse[0].role !== rowData.role) {
              onRowEditComplete(rowData, "role");
            } else if (getRepResponse[0].signType !== rowData.signType) {
              onRowEditComplete(rowData, "signType");
            }
          });
      }
    }
  };

  const textEditorFName = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onBlur={(e) => representativeInputOnBlur(e, "FName", options.rowData)}
      />
    );
  };

  const textEditorLName = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onBlur={(e) => representativeInputOnBlur(e, "LName", options.rowData)}
      />
    );
  };

  const textEditorMask = (options) => {
    return (
      <InputMask
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        mask="99999999-9999"
        onBlur={(e) => representativeInputOnBlur(e, "SSN", options.rowData)}
        // required
        placeholder={TranslationFile.SSNFormat[userSelectedLanguage]}
      />
    );
  };

  const roleEditOnChange = (e, options) => {
    options.rowData.role = e;
  };

  const roleEditOnBlur = (e, options) => {
    representativeInputOnBlur(e, "role", options);
  };

  const roleEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        editable
        options={repRoleOptions}
        onChange={(e) => roleEditOnChange(e.target.value, options)}
        onBlur={(e) => roleEditOnBlur(e, options.rowData)}
        placeholder={TranslationFile.SelectAStatusText[userSelectedLanguage]}
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const signTypeBodyTemplate = (rowData) => {
    let signType = "Paper";
    if (rowData.signType > 0) signType = "Digital Signature";
    return <Tag value={signType}></Tag>;
  };

  const signTypeEditor = (options) => {
    let signType = "Paper";
    if (options.value > 0) signType = "Digital Signature";
    return (
      <Dropdown
        value={signType}
        // editable
        className="signTypeEditDropdown"
        options={signTypeOptions}
        onChange={(e) => signTypeEditOnChange(e.target.value, options)}
        onBlur={(e) => signTypeEditOnBlur(e, options.rowData)}
        placeholder={TranslationFile.SelectASignTypeText[userSelectedLanguage]}

        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const signTypeEditOnChange = (e, options) => {
    if (e == "Digital Signature" && options.rowData.sSN != "") {
      options.rowData.signType = e;
    } else if (e == "Paper") {
      options.rowData.signType = e;
    } else {
      toast.current.show({
        severity: "error",
        summary: TranslationFile.errorText[userSelectedLanguage],
        detail: (
          <>
            {TranslationFile.repToast1[userSelectedLanguage]}{" "}
            <b> {TranslationFile.repDigitalSignature[userSelectedLanguage]}</b>
          </>
        ),
        life: 6000,
      });
    }
  };

  const signTypeEditOnBlur = (e, options) => {
    representativeInputOnBlur(e, "signType", options);
  };

  const callback = (annualReport, session, link, status) => {
    if (status) {
      history.push(annualReport + session + link);
    }
  };


  return (
    <div>
      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps={true}
        disableScrolling={false}
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <NavBarHeader callBack={callback} isAppPortal={true} isHomePage={false} />
      <div className="representative-sidebar-div">
        <Sidebar
          children="Digital Signature"
          sideBarStatus={(e) => sideBarStatusFnc(e)}
          sideBarCallback={(e) => sideBarCallback(e)}
          tourStatus={tourStatus}
        />
      </div>
      <Toast ref={toast} />
      <div
        className={
          sideBarStatus
            ? "digitalSignature-div"
            : "digitalSignature-div-sidebar"
        }
      >
        <div className="digitalSignPdfDiv">
          <Row>
            <Col>
              <div>
                <div className="Page_Title_ds">
                  <div
                    className="Page_Title_T1_ds_name"
                  >{companyName}</div>
                  <div
                    className="Page_Title_ds_Year"
                  >{currentFinancialYear}</div>
                </div>
              </div>
            </Col>
            <Col>
              <Button
                type="button"
                icon="pi pi-file-pdf"
                outlined
                style={{ borderRadius: "70px" }}
                className="pdf_view_sig"
                tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
                tooltipOptions={{ position: "bottom" }}
                onClick={() => previewPdf(currentReportGUIDFromUrl)}
              />
            </Col>
          </Row>
        </div>

        <Row style={{ width: "100%" }}>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ width: "100%", marginLeft: "12px" }}
          >
            <center>
              <DataTable
                value={digitalignatureReps}
                className="digitalSignatureDataTable"
                editMode="row"
              >
                <Column
                  field="firstName"
                  header={TranslationFile.FirstNameText[userSelectedLanguage]}
                  style={{ width: "20%" }}
                  editor={(options) => textEditorFName(options)}
                ></Column>
                <Column
                  field="lastName"
                  header={TranslationFile.LastName[userSelectedLanguage]}
                  style={{ width: "20%" }}
                  editor={(options) => textEditorLName(options)}
                ></Column>
                <Column
                  field="sSN"
                  header="SSN"
                  style={{ width: "20%" }}
                  editor={(options) => textEditorMask(options)}
                ></Column>

                <Column
                  field="role"
                  header={TranslationFile.repRole[userSelectedLanguage]}
                  style={{ width: "20%" }}
                  editor={(options) => roleEditor(options)}
                ></Column>

                <Column
                  field="signType"
                  header={TranslationFile.signTypeText[userSelectedLanguage]}
                  className="representative-signType"
                  style={{ width: "20%" }}
                  body={signTypeBodyTemplate}
                  editor={(signTypeObj) => signTypeEditor(signTypeObj)}
                ></Column>

                <Column
                  header={TranslationFile.DigitalSignStatus[userSelectedLanguage]}
                  body={(e) => digitalSignatureStatusTemplate(e)}
                ></Column>

                <Column
                  rowEditor
                  headerStyle={{ width: "20%", minWidth: "8rem" }}
                  bodyStyle={{ textAlign: "center" }}
                  className="representativeRowEdit"
                ></Column>
              </DataTable>
              <Dialog
                className="digitalSigantureBankID_Dialogbox"
                // header="Digital Signature"
                visible={visibleQrCodeDialogBox}
                blockScroll
                onHide={() => {
                  signatureDialogBoxOnHide();
                }}
              >
                <div className="bankIdIframe">
                  {digitalSignSuccess && !digitalSignFail ? (
                    <div class="centerQQ">
                      <center>
                        <div className="digitalSignCheckRespCardDiv">

                          <i
                            className="pi pi-check-circle"
                            id="digitalSignCheckRespIcon"
                            style={{ color: "#9ABC66" }}
                          ></i>
                        </div>

                        <h1
                          className="digitalSignCheckRespHeading"
                          style={{ color: "#88B04B" }}
                        >
                          {/* Success */}
                          {TranslationFile.SuccessText[userSelectedLanguage]}
                        </h1>

                        <p className="digitalSignCheckRespDetails">
                          {TranslationFile.BankIDsuccessDoneText[userSelectedLanguage]}
                        </p>
                      </center>
                    </div>
                  ) : !digitalSignSuccess && digitalSignFail ? (
                    <div class="centerQQ">
                      <center>
                        <div className="digitalSignCheckRespCardDiv">
                          <i
                            className="pi pi-times"
                            id="digitalSignCheckRespIcon"
                            style={{ color: "red" }}
                          ></i>
                        </div>

                        <h1
                          className="digitalSignCheckRespHeading"
                          style={{ color: "red" }}
                        >
                          {TranslationFile.AuthenticationFailedText[userSelectedLanguage]}
                        </h1>

                        <p className="digitalSignCheckRespDetails">
                          {/* Please verify the Representative info and try again */}
                          {TranslationFile.VerifyRepInfoText[userSelectedLanguage]}
                        </p>
                        <div className="digitalSignatureQRFailSSN">
                          <b>SSN : {currentRep.ssn}</b>
                        </div>
                      </center>
                    </div>
                  ) : (
                    !digitalSignFail &&
                    !digitalSignSuccess && (
                      <div class="centerQQ">
                        <center>
                          <div className="bankIdLogoDiv">
                            <img
                              src={BankIdLogo}
                              className="bankIdLogo"
                              width={100}
                              height={100}
                            />
                            <b> {TranslationFile.DSvisBankIDText[userSelectedLanguage]}</b>

                            <br />
                            <br />
                          </div>
                          <div className="ds-steps-title">
                            <Row>
                              <b> {TranslationFile.digitalSignLoginTitle[userSelectedLanguage]}</b>
                            </Row>
                          </div>
                          <div className="ds-steps">
                            <Row>
                              1. {TranslationFile.digitalSignLoginStep1[userSelectedLanguage]}
                            </Row>
                            <Row>
                              2. {TranslationFile.digitalSignLoginStep2[userSelectedLanguage]}
                            </Row>
                            <Row>
                              3. {TranslationFile.digitalSignLoginStep3[userSelectedLanguage]}
                            </Row>
                          </div>
                          <br />
                          <div className="digitalSignDialogScannerDiv">
                            <div className="bankIdQRScanner">
                              {qrCodeLoading ? (
                                <div className="digitalSignQrCodeLoadingDiv">
                                  <ProgressSpinner className="digitalSignQrCodeProgress" />
                                </div>
                              ) : (
                                <img src={qrImage} />
                                // <>BASHA</>
                              )}
                            </div>
                          </div>
                        </center>
                      </div>
                    )
                  )}
                </div>
              </Dialog>
            </center>
          </Col>
        </Row>
      </div>
      <center>
        <Button
          label={TranslationFile.reviewSendText[userSelectedLanguage]}
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={() => navigateToReviewAndSend()}
        />
      </center>
    </div>
  );
}
