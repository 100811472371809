import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  FcDocument,
  FcFilingCabinet,
  FcAbout,
  FcAssistant,
  FcInspection,
  FcNeutralTrading,
  FcOpenedFolder,
  FcKindle,
  FcSignature,
  FcMoneyTransfer,
  FcTodoList,
  FcApproval,
  FcBusinessman,
  FcAcceptDatabase,
  FcDecision,
  FcDatabase,
  FcIdea,
} from "react-icons/fc";
import { MdOutlineHelp } from "react-icons/md";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { useSelector } from "react-redux";
import TickIcon from "../../Assests/tickIcon.png";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import galaxy from "../../Assests/galaxy.png";
import { InputSwitch } from "primereact/inputswitch";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import axios from "axios";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import Cookies from "js-cookie";

import "./Sidebar.css";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    payment: state.payment.payment,
  };
};

let steps = [
  {
    content: 'Switch on the Guided tour to explore the app',
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".tourguide_toogle_btn",
    title: 'Explore the app',
    disableBeacon: true,
  },
];


let userSelectedLanguage;
let uuid;
let possibleNavigationPagesList = ["My Dashboard", "Company Information"];


let localData = localStorage.getItem("rebelSkoolUser");
let localObject = "";
if (localData != "undefined") {
  localObject = JSON.parse(localData);
}


// let selectedSideMenu = "Company Information 22";
let showInfoToast = false;
const Sidebar = ({ children, sideBarStatus, switchValue, sideBarCallback, tourStatus }) => {
  const history = useHistory();
  const toast = useRef(null);
  const [isDummy, setIsDummy] = useState(true);
  const [position, setPosition] = useState("center");
  const [selectedSideMenu, setSelectedSideMenu] = useState("");
  const [hidePaymentMenu, setHidePaymentMenu] = useState(false);
  const [isReportCreated, setReportCreated] = useState(false);
  const [isInDashboardPage, setInDashboardPage] = useState(false);
  const [reportAccess, setReportAccess] = useState(null);
  const toastBC = useRef(null);
  const sessionDetailsReducer = useSelector((state) => state.sessionDetails);
  const activeSideBarReducer = useSelector(
    (state) => state.activeSideBar.activeSideBar.values
  );

  let sessionUUIDFromLS = localStorage.getItem("sessionUUID");

  uuid = sessionDetailsReducer["sessionDetails"]["values"]
    ? sessionDetailsReducer["sessionDetails"]["values"].uuid
    : sessionUUIDFromLS;
  const annualReport = "/annualreport/";
  const balanceSheet = annualReport + uuid + "/balanceSheet";
  const notes = annualReport + uuid + "/notes";
  const managementStatement = annualReport + uuid + "/managementStatement";
  const representative = annualReport + uuid + "/representative";
  const companyInformationUrl = annualReport + uuid + "/companyInfo";
  const incomeStatement = annualReport + uuid + "/incomeStatement";
  const payment = annualReport + uuid + "/payment";
  const dashboard = annualReport + uuid + "/dashboard";
  const reviewAndSend = annualReport + uuid + "/reviewAndSend";

  const userGUID =
    localObject && localObject.userGUID ? localObject.userGUID : uuid;

  const mainDashboard = "/" + userGUID + "/MainDashboard"


  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  let consentValue = Cookies.get("RBLCookieConsent");
  let tourGuideResult = JSON.parse(localStorage.getItem("TourGuide")) || false;
  const [inputCheck, setInputCheck] = useState(tourGuideResult);

  const routes = [
    {
      name: (
        <div>
          <button className="new-annual-report-create-button">
            {/* New Report */}
            {TranslationFile.sidebarNewReport[userSelectedLanguage]}
          </button>
        </div>
      ),
      icon: <img className="new-annual-report-create" src={galaxy}></img>,
      path: "/year",
    },
    {
      swName: "Mina årsredovisningar",
      name: "My Dashboard",
      icon: <FcOpenedFolder className="icon" />,
      // path: dashboard,
      path: mainDashboard,
      target: "_blank",
    },
    {
      swName: "Företagsinformation",
      name: "Company Information",
      icon: <FcFilingCabinet className="icon" />,
      path: companyInformationUrl,
    },

    {
      swName: "Resultaträkning",
      name: "Income Statement",
      icon: <FcKindle className="icon" />,
      path: incomeStatement,
    },
    {
      swName: "Balansräkning",
      name: "Balance Sheet",
      icon: <FcNeutralTrading className="icon" />,
      path: balanceSheet,
    },
    {
      swName: "Noter",
      name: "Notes",
      icon: <FcInspection className="icon" />,
      path: notes,
    },
    {
      swName: "Förvaltningsberättelse",
      name: "Management Statement",
      icon: <FcDocument className="icon" />,
      path: managementStatement,
    },
    {
      swName: "Befattningshavare",
      name: "Representative",
      icon: <FcDatabase className="icon" />,
      path: representative,
    },
    {
      swName: "Betalning",
      name: "Payment",
      icon: <FcMoneyTransfer className="icon" />,
      path: payment,
    },
    {
      swName: "signering",
      name: "Signature",
      icon: <FcSignature className="icon" />,
      path: "/info",
    },

    {
      swName: "Granska och skicka",
      name: "Review And Send",
      icon: <FcTodoList className="icon" />,
      path: reviewAndSend,
    },

    {
      swName: "Hjälp/exempel",
      name: "Help/Example Report",
      icon: <FcDecision className="icon" />,
      path: "#/fileSIE",
    },
    {
      swName: "Hjälpvisning",
      name: (
        <div className="tourguide_toogle_btn">
          <span id="sidebar_iconTourGuide"> <FcIdea className="icon" /></span>
          <span id="sidebar_tourguide">Tour Guide</span>
          <InputSwitch
            checked={inputCheck}
            onChange={(e) => tourGuideFunc(e)}
          />
        </div>
      ),
      path: "",
    },
  ];
  const [isOpen, setIsOpen] = useState(true);
  const [showRun, setShowRun] = useState(false);

  ///////////////////////////////////


  ///////////////////////////////////

  const toggle = () => {
    setIsOpen(!isOpen);
    sideBarStatus(!isOpen);
  };

  const sideBarReducer = useSelector((state) => state.sideBarReducer);
  const companyInformationReducer = useSelector(
    (state) => state.companyInformation
  );

  const financialYearReducer = useSelector((state) => state.financialYear);
  let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
  let possibleAccessablePagesListLSString = localStorage.getItem(
    "possibleAccessablePages"
  );
  let sideBarReducerValues = [];
  if (null != possibleAccessablePagesListLSString)
    sideBarReducerValues = JSON.parse(possibleAccessablePagesListLSString);
  let splittedUrl = window.location.href.split("/");
  let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
  let currentPageNameFromUrl = splittedUrl[splittedUrl.length - 1];

  const tourGuideFunc = (e) => {
    setInputCheck(e.value);
    localStorage.setItem("TourGuide", e.value);
    tourStatus(e.value);
  };

  const checkingOverlayLS = () => {
    let getOverlayFromLS = localStorage.getItem("guidedTourCount");
    if (getOverlayFromLS != undefined && getOverlayFromLS != null) {
      //more than one time
      if (getOverlayFromLS > 0) {
        setShowRun(false);
        getOverlayFromLS = Number(getOverlayFromLS) + 1;
        localStorage.setItem("guidedTourCount", getOverlayFromLS);
      }
    } else {
      //first time

      setShowRun(true);
      localStorage.setItem("guidedTourCount", 1);
    }
  };

  useEffect(() => {
    if (activeSideBarReducer != undefined)
      setSelectedSideMenu(activeSideBarReducer[0]);

    if (
      currentPageNameFromUrl == "dashboard" ||
      currentPageNameFromUrl == "MyProfile"
    )
      setInDashboardPage(true);
    else setInDashboardPage(false);

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let currentPageFromLS = localStorage.getItem("currentPageName");
          if (response.data.reportID == null) {
            setReportCreated(false);
          } else {
            let reportAccessReq = {
              reportId: response.data.reportID,
            };
            axios
              .post(
                "/annualReport/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {

                  case 2:
                    //Write
                    setReportAccess(2)
                    break;
                  case 3:
                    //Read Only
                    setReportAccess(3)
                    break;
                  case 4:
                    //No Access
                    setReportAccess(4)
                    break;
                }

              })
              .catch((error) => {
                let noAccessURL =
                  "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                history.push(noAccessURL);
              });
            setReportCreated(true);
          }
          if (
            response.data.reportID == null &&
            currentPageFromLS &&
            (currentPageFromLS == "Payment" ||
              currentPageFromLS == "Dashboard" ||
              currentPageFromLS == "MyProfile")
          ) {
            setIsOpen(false);
            sideBarStatus(false);
          }
          if (response.data.paymentEntryNo > 0 || reportAccess == 2 || reportAccess == 3) {
            setHidePaymentMenu(true);
          }
        });
    }

    if (children === "Company Information") {
      if (consentValue == true) {
        checkingOverlayLS()
      } else {
        setTimeout(() => {
          checkingOverlayLS()
        }, 6000);
      }


    }

  }, []);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
    },
    show: {
      opacity: 1,
      width: "auto",
    },
  };
  const newReport = () => {
    localStorage.setItem("navigateTo", "companyInformation");
    localStorage.setItem("navigateToGUID", "");
    localStorage.setItem("navigatedCompanyName", "");
    localStorage.setItem("possibleAccessablePages", "");

    history.push("/annualreport");
    window.location.reload(false);
  };

  const show = (position) => {
    let financialYearReducerValue = financialYearReducer.financialYear.values;
    let companyInformationReducerValue =
      companyInformationReducer.companyInformation.values;

    setPosition(position);
    let isFinancialYearReducerValueEmpty = false;

    if (financialYearReducerValue != undefined) {
      isFinancialYearReducerValueEmpty = true;
      if (Object.keys(financialYearReducerValue).length === 0) {
        isFinancialYearReducerValueEmpty = false;
      }
    }

    if (position == "top") {
      newReport();
      let possibleNavigationPagesList = ["My Dashboard"];
      let possibleNavigationPagesListString = JSON.stringify(
        possibleNavigationPagesList
      );
      localStorage.setItem(
        "possibleAccessablePages",
        possibleNavigationPagesListString
      );
    }
  };

  const callback = (value) => {
    sideBarCallback(value);
  };

  const sideMenuOnClick = (event, selectedSideMenuName) => {
    localStorage.setItem("navigateToGUID", "");

    let possibleAccessablePagesListLS = localStorage.getItem(
      "possibleAccessablePages"
    );

    if (selectedSideMenuName == "Company Information") {
      const annualReport = "/annualreport/";
      history.push(annualReport + currentReportGUIDFromUrl + "/companyInfo");
    }
    if (selectedSideMenuName == "My Dashboard") {
      const annualReport = "/annualreport/";
      history.push(annualReport + currentReportGUIDFromUrl + "/dashboard");
    }

    let possibleAccessablePagesListLSArray =
      possibleAccessablePagesListLS &&
      JSON.parse(possibleAccessablePagesListLS);

    let companyInformationReducerValue =
      companyInformationReducer.companyInformation.values;
    callback(false);
    if (
      null == possibleAccessablePagesListLSArray ||
      (null != possibleAccessablePagesListLSArray &&
        !possibleAccessablePagesListLSArray.includes(selectedSideMenuName))
    ) {
      if (!showInfoToast) {
        showInfoToast = true;
        callback(true);
        showInfoToast = false;
      }

      event.preventDefault();
    } else if (
      companyInformationReducerValue != undefined &&
      (companyInformationReducerValue.city == "" ||
        companyInformationReducerValue.postalcode == 0)
    ) {
      if (!showInfoToast) {
        const button = document.evaluate(
          '//*[@class="p-button p-component year-btn-save"]',
          document,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;

        if (button) {
          button.click();
        }

        showInfoToast = true;

        setTimeout(() => {
          showInfoToast = false;
        }, 2000);
      }
      event.preventDefault();
    }
    setSelectedSideMenu(selectedSideMenuName);
  };

  return (
    <div>
      <Toast ref={toast} className="sideBarNotAccessableToast" />
      <Joyride
        continuous
        run={showRun}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps={true}
        disableScrolling={true}
        scrollOffset={100}
        locale={{
          next: TranslationFile.nextBtn[userSelectedLanguage], // Translated "Next" to "Nästa"
          back: TranslationFile.backText[userSelectedLanguage], // Translated "Back" to "Tillbaka"
          skip: TranslationFile.skipText[userSelectedLanguage], // Translated "Skip" to "Skippa"
          last: "OK", // Translated "Last" to "Sista"
          close: "Close", // Keep "Close" as is (or translate it if needed)
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <div
        className={
          isOpen
            ? "main-container-sidebarOpenClass"
            : "main-container-sidebarCloseClass"
        }
      >
        <motion.div
          animate={{
            width: isOpen ? "250px" : "50px",
          }}
          className={switchValue ? "sidebarExtent" : `sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <p className="subtext-annual-report">
                  {/* Annual Report */}
                  {TranslationFile.sidebarText1[userSelectedLanguage]}
                </p>
              )}
            </AnimatePresence>

            <div className="bars">
              {isOpen == true ? (
                <RxDoubleArrowLeft className="icon" onClick={toggle} />
              ) : (
                <RxDoubleArrowRight className="icon" onClick={toggle} />
              )}
            </div>
          </div>

          <section className="routes">
            {/* NEW ANNUAL REPORT */}
            {routes[0].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <div
                className="link"
                onClick={() => show("top")}
                style={{ cursor: "pointer" }}
              >
                <div className="icon" style={{ marginRight: "-11px" }}>
                  {routes[0].icon}
                </div>
                <div className="menu-names-link">
                  {isOpen && <div>{routes[0].name}</div>}
                </div>
              </div>
            )}

            {/* MY DASHBOARD */}
            {routes[1].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[1].path}
                // className="link"
                className={
                  children == "MyDashboard" ? "sidebarActive" : "notesLink"
                }
                activeClassName="active"
                onClick={(e) => sideMenuOnClick(e, routes[1].name)}
              >
                <div className="icon">{routes[1].icon}</div>
                <div className="menu-names-link">
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[1].swName
                        : routes[1].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* COMPANY INFORMATION */}
            {routes[2].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[2].path}
                className={
                  children == "Company Information"
                    ? "sidebarActive"
                    : "notesLink"
                }
                activeClassName="active"
                onClick={(e) => sideMenuOnClick(e, routes[2].name)}
              >
                <div className="icon">
                  {routes[2].icon}
                  {sideBarReducerValues &&
                    sideBarReducerValues.includes("Company Information") && (
                      <FcApproval className="tickIcon" />
                    )}
                </div>
                <div className="menu-names-link">
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[2].swName
                        : routes[2].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* INCOME STATEMENT */}
            {routes[3].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[3].path}
                className={
                  children == "Income Statement" ? "sidebarActive" : "notesLink"
                }
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[3].name)}
              >
                <div className="icon">
                  {routes[3].icon}{" "}
                  {sideBarReducerValues.includes("Income Statement") && (
                    <FcApproval className="tickIcon" />
                  )}
                </div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[3].swName
                        : routes[3].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* BALANCE SHEET */}
            {routes[4].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[4].path}
                className={
                  children == "Balance Sheet" ? "sidebarActive" : "notesLink"
                }
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[4].name)}
              >
                <div className="icon">
                  {routes[4].icon}
                  {sideBarReducerValues.includes("Balance Sheet") && (
                    <FcApproval className="tickIcon" />
                  )}
                </div>

                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[4].swName
                        : routes[4].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* NOTES */}
            {routes[5].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[5].path}
                className={children == "Notes" ? "sidebarActive" : "notesLink"}
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[5].name)}
              >
                <div className="icon">
                  {routes[5].icon}
                  {sideBarReducerValues.includes("Notes") && (
                    <FcApproval className="tickIcon" />
                  )}
                </div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[5].swName
                        : routes[5].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* MANAGEMENT STATEMENT */}
            {routes[6].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[6].path}
                className={
                  children == "Management Statement"
                    ? "sidebarActive"
                    : "notesLink"
                }
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[6].name)}
              >
                <div className="icon">
                  {routes[6].icon}
                  {sideBarReducerValues.includes("Management Statement") && (
                    <FcApproval className="tickIcon" />
                  )}
                </div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[6].swName
                        : routes[6].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* REPRESENTATIVE */}
            {routes[7].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[7].path}
                className={
                  children == "Representative" ? "sidebarActive" : "notesLink"
                }
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[7].name)}
              >
                <div className="icon">
                  {routes[7].icon}
                  {sideBarReducerValues.includes("Representative") && (
                    <FcApproval className="tickIcon" />
                  )}
                </div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[7].swName
                        : routes[7].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* PAYMENT */}
            {!hidePaymentMenu && (
              <div>
                {routes[8].subRoutes ? (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={routes}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                ) : (
                  <NavLink
                    to={routes[8].path}
                    className={
                      children == "Payment" ? "sidebarActive" : "notesLink"
                    }
                    activeClassName="active"
                    style={{
                      backgroundColor:
                        !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                    }}
                    onClick={(e) => sideMenuOnClick(e, routes[8].name)}
                  >
                    <div className="icon">
                      {routes[8].icon}
                      {sideBarReducerValues.includes("Payment") && (
                        <FcApproval className="tickIcon" />
                      )}
                    </div>
                    <div
                      className="menu-names-link"
                      style={{
                        color:
                          !isReportCreated && isInDashboardPage
                            ? "gray"
                            : "black",
                      }}
                    >
                      {isOpen && (
                        <div>
                          {userSelectedLanguage == "sw"
                            ? routes[8].swName
                            : routes[8].name}
                        </div>
                      )}
                    </div>
                  </NavLink>
                )}
              </div>
            )}


            {/* DIGITAL SIGNATURE */}
            {routes[9].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[9].path}
                // className="link"
                className={
                  children == "Digital Signature" ? "sidebarActive" : "notesLink"
                }
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[9].name)}
              >
                <div className="icon">{routes[9].icon}
                  {sideBarReducerValues.includes("Digital Signature") && (
                    <FcApproval className="tickIcon" />
                  )}

                </div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[9].swName
                        : routes[9].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* REVIEW AND SEND */}
            {routes[10].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[10].path}
                className={
                  children == "Review And Send" ? "sidebarActive" : "notesLink"
                }
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[10].name)}
              >
                <div className="icon">
                  {routes[10].icon}
                  {sideBarReducerValues.includes("Review And Send") && (
                    <FcApproval className="tickIcon" />
                  )}
                </div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[10].swName
                        : routes[10].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}



            {/* HELP/EXAMPLE REPORT */}
            {routes[11].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[11].path}
                className="link"
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[11].name)}
              >
                <div className="icon">{routes[11].icon}</div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[11].swName
                        : routes[11].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {routes[12].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <div
                className="link"
                style={{ cursor: "pointer" }}
              >
                <div className="menu-names-link">
                  {isOpen && <div>{routes[12].name}</div>}
                </div>
              </div>
            )}
          </section>
        </motion.div>
      </div>
    </div>
  );
}

export default Sidebar;
