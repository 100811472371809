import React, { useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import MyDashboard from "../MyDashboard/MyDashboard";
import SRUDashboard from "../SRU/SRUDashboard/SRUDashboard";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import SRUNavBarHeader from "../SRU/SRUNavBarHeader/SRUNavBarHeader";
import { Toast } from "primereact/toast";


const MainDashboard = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Tracks the active tab index

    const callback = () => {
        console.log("Callback triggered");
    };

    return (
        <div className="card">
            {/* Render NavBarHeader for AnnualReport Dashboard and SRUNavBarHeader for SRU Dashboard */}
            {activeIndex === 0 && (
                <NavBarHeader isAppPortal={true} isHomePage={false} callBack={callback} />

            )}
            {activeIndex === 1 && (
                <SRUNavBarHeader isAppPortal={true} isHomePage={false} callBack={callback} />

            )}

            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} style={{ marginTop: "4%", marginLeft: "4%" }}>
                <TabPanel header="AnnualReport Dashboard">
                    <MyDashboard />
                </TabPanel>
                <TabPanel header="SRU Dashboard">
                    <SRUDashboard />
                </TabPanel>
            </TabView>
        </div>
    );
};


export default MainDashboard;