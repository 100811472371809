import React, { Component } from "react";
import { Fieldset } from "primereact/fieldset";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import axios from "axios";
import ScrolltoTop from "../../ScrollTop/ScrollTop";
import { connect } from "react-redux";
import Sidebar from "../SRUSidebar/SRUSidebar";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Password } from "primereact/password";
import { setUserSessionAuthKey } from "../../../Component/commonUtils";
import TranslationFile from "../../15-Translation/locale/Translation_apps.json";
import { ProgressSpinner } from "primereact/progressspinner";
import SRUNavBarHeader from "../SRUNavBarHeader/SRUNavBarHeader";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Dropdown } from "primereact/dropdown";
import "./SRUCompanyInfo.css";
import Footer_contact_tab from "../../Footerpage/Footer_contact_tab";
import { MultiSelect } from "primereact/multiselect";
import { setupLocales } from "../../../Component/setupLocales";

const mapStateToProps = (state) => {
  return {
    companyInformation: state.companyInformation.companyInformation,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    currentPageName: state.currentPageName.currentPageName,
    readOnly: state.readOnly.readOnly,
    pricesToLogin: state.pricesToLogin.pricesToLogin,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
  };
};
let dataKeyIn = "" , dateValue = '';

let currentYearObj = {from:'',to:''}
let financialYearObj = {
  from: "",
  to: "",
}, localeLang = "",
  financialYearArray = [],
  SIEFileContentArray = [],
  sideBarGreenTickPages = [];
let companyInformationFyPropsValue = "";
let selectedFile = "",
  submissionLimitToDate,
  linkSharedUser,
  responsePaymentEntryNo = 0,
  sruReportHeaderRequestObj = {
    reportGUID: "",
    companyName: "",
    registrationNo: "",
    postalCode: 0,
    city: "",
    currentYear: "",
    forms:"",
  },
  localData = "",
  localObject,
  // dateValue,
  userDetailsFromLocalStorage,
  currentReportGUIDFromUrl;
let formattedRanges = [];

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let userSelectedLanguage;
class SRUCompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationNumber: "",
      disableCompanyNo: false,
      companyName: "",
      postalCode: null,
      city: "",
      currentYear:"",
      currentFromDate:"",
      currentToDate:"",
      isWrongOrganizationNo: false,
      checkCompanyName: false,
      isWrongPostalcodeFormat: false,
      checkCity: false,
      isExistingOrganization: false,
      fetchOrgNoApiCallStatus: false,
      importSIEFile: false,
      isOrganizationNoMissing: false,
      isOrganizationNameMissing: false,
      isPostalCodeMissing: false,
      isCityMissing: false,
      selectedSRUYearMissing: false,
      selectedSRUYearQuarterPortionMissing: false,
      SelectedSRUFormMissing: false,
      callBackFromMergeYearValue: "",
      displayLabels: false,
      // sliderValue: 0,
      financialYearArrayState: [],
      SIEMissingFieldsErrorArrayState: [],
      visible: false,
      showProceedButton: false,
      isNavigated: false,
      showUserNamePasswordDialogBox: false,
      newUserPassword: "",
      newUserConfirmPassword: "",
      newUserName: "",
      newUserUserId: "",
      newUserEmailId: "",
      currentStartingDate: "",
      calendarDateHardCodedValue: "",
      userSelectedDateState: "",
      isProceedButtonClicked: false,
      uploadedSIEFile: "",
      currentReportID: "",
      // hidePage:'',
      reportAccessStatusFromProps: "",
      selectedSRUYear: null,
      selectedSRUYearQuarterPortion: null,
      SelectedSRUForm: null,
      showRemoveConfimationDialog: false,
      deleteForm: null,
      isMasked: true,
      SelectedSRUForm2: [
        { name: "INK2", id: 1 },
        { name: "INK2R", id: 2 },
        { name: "INK2S", id: 3 },
      ],
      SRUForm: [
        { name: "INK2", id: 1 },
        { name: "INK2R", id: 2 },
        { name: "INK2S", id: 3 },
      ],
    };

    this.SRUForm = [
      { name: "INK2", id: 1 },
      { name: "INK2R", id: 2 },
      { name: "INK2S", id: 3 },
    ];

    // this.SRUyear = [{ name: "2024" }, { name: "2023" }, { name: "2022" }];

    this.SRUYearQuarterPortion = [];

    this.callbackSIE = this.callbackSIE.bind(this);
    this.onSRUYearChange = this.onSRUYearChange.bind(this);
    // this.onSRUYearQuarterPortionChange =
    //   this.onSRUYearQuarterPortionChange.bind(this);
  }

  callbackRemoveSIE = (SIEError) => {
    this.state({
      SIEMissingFieldsErrorArrayState: [],
    });
  };

  onSRUYearChange(e) {
    this.setState({ selectedSRUYear: e.value });
    axios
      .post("/SRUHeader/generateQuarterPeriods?year=" + e.value.name)
      .then((response) => {
        let respData = response.data;
        formattedRanges = respData.map((range, index) => {
          return { name: `P${index + 1}: ${range}` };
        });
        this.SRUYearQuarterPortion = formattedRanges;
        this.setState({ reRender: true });
      });
  }

  // onSRUYearQuarterPortionChange(e) {
  //   const { SelectedSRUForm2 } = this.state;
  //   this.setState({ selectedSRUYearQuarterPortion: e.value });
  //   sruReportHeaderRequestObj.currentYear = e.value.name;

  //   if (SelectedSRUForm2 && SelectedSRUForm2.length > 0) {
  //     let sortArray = [...SelectedSRUForm2].sort((a, b) => a.id - b.id);
  //     let onlyFormName = "";
  //     sortArray.forEach((val, idx) => {
  //       if (idx == 0) {
  //         onlyFormName = onlyFormName + val.name;
  //       } else {
  //         onlyFormName = onlyFormName + "," + val.name;
  //       }
  //     });

  //     if (onlyFormName != null && onlyFormName != "") {
  //       sruReportHeaderRequestObj.forms = onlyFormName;
  //     }
  //   }
  //   this.insertSRUReportHeader();
  // }

  callbackSIE = (SIEFile) => {
    const { financialYearArrayState, organizationNumber, companyName } =
      this.state;
    let { companyInformation, login, sessionDetails } = this.props;

    let SIEFinancialYearArray = [];

    let companyInformationValue = {
      organizationname: "",
      organizationnumber: "",
      postalcode: "",
      city: "",
      financialYear: "",
    };

    let isSIEOrgNoExist = false,
      SIEOrgNoError = "",
      SIEFinancialYearError = "",
      SIEInvalidFinancialYearLineNo = 0,
      isSIEOrgNameExist = false,
      isSIEFinancialYearExist = true;

    SIEFile.length &&
      SIEFile.map((i, idx) => {
        let SIEFinancialYearObj = {
          from: "",
          to: "",
        };

        if (i.includes("#ORGNR")) {
          let SIEOrgNo = i.split("#ORGNR");
          let organizationNo = SIEOrgNo[1].replace(" ", "");
          if (SIEOrgNo.length > 1 && organizationNo != "") {
            let organizationNoCount = organizationNo.length;

            if (organizationNoCount < 10 || organizationNoCount > 11) {
              SIEOrgNoError = "Orgnization No. (#ORGNR) is invalid";
            } else {
              if (organizationNoCount == 11) {
                this.setState({
                  organizationNumber: organizationNo,
                });

                companyInformationValue.organizationnumber = organizationNo;
                sruReportHeaderRequestObj.registrationNo = organizationNo;
              } else {
                var chr = "-";
                var pos = 6;

                organizationNo =
                  organizationNo.substring(0, pos) +
                  chr +
                  organizationNo.substring(pos, organizationNo.length);
                this.setState({
                  organizationNumber: organizationNo,
                });

                sruReportHeaderRequestObj.registrationNo = organizationNo;
              }
              isSIEOrgNoExist = true;
            }
          }
        }

        if (i.includes("#FNAMN")) {
          let SIEOrgName = i.split("#FNAMN");
          if (SIEOrgName.length > 1 && SIEOrgName[1] != "") {
            let orgName = SIEOrgName[1].replace(/['"]+/g, "").substring(1);
            this.setState({
              companyName: orgName,
            });
            isSIEOrgNameExist = true;

            companyInformationValue.organizationname = orgName;
            sruReportHeaderRequestObj.companyName = orgName;
          }
        }

        let formattedFromDateString = "",
          formattedToDateString = "";
        if (i.includes("#RAR")) {
          let SIEFinancialYear = i.split(" ");
          if (SIEFinancialYear.length > 1) {
            let SIEFromDate = SIEFinancialYear[2];
            if (SIEFromDate.length == 8) {
              formattedFromDateString =
                SIEFromDate.slice(0, 4) +
                "-" +
                SIEFromDate.slice(4, 6) +
                "-" +
                SIEFromDate.slice(6, 8);

              SIEFromDate = new Date(formattedFromDateString);
            } else {
              isSIEFinancialYearExist = false;
              SIEInvalidFinancialYearLineNo = idx + 1;

              if (SIEFinancialYearError != "") {
                SIEFinancialYearError += "," + SIEInvalidFinancialYearLineNo;
              } else {
                SIEFinancialYearError =
                  "Invalid Financial Year (#RAR) in line No: " +
                  SIEInvalidFinancialYearLineNo;
              }
            }

            let SIEToDate = SIEFinancialYear[3];

            if (SIEToDate.length == 8) {
              formattedToDateString =
                SIEToDate.slice(0, 4) +
                "-" +
                SIEToDate.slice(4, 6) +
                "-" +
                SIEToDate.slice(6, 8);

              SIEToDate = new Date(formattedToDateString);
            } else {
              isSIEFinancialYearExist = false;
              if (idx + 1 != SIEInvalidFinancialYearLineNo) {
                if (SIEFinancialYearError != "") {
                  SIEFinancialYearError += "," + (idx + 1);
                } else {
                  SIEFinancialYearError =
                    "Invalid Financial Year (#RAR) in line No: " + (idx + 1);
                }
              }
            }

            if (isSIEFinancialYearExist) {
              SIEFinancialYearObj.from = SIEFromDate;
              SIEFinancialYearObj.to = SIEToDate;

              SIEFinancialYearArray.push(SIEFinancialYearObj);
              companyInformationValue.financialYear = SIEFinancialYearArray;
            }

            let dateRange =
              formattedFromDateString + " - " + formattedToDateString;
          }
        }
      });

    if (!isSIEOrgNoExist) {
      if (SIEOrgNoError == "") {
        SIEOrgNoError = "Orgnization No. (#ORGNR) is missing";
      }
      this.setState((prevState) => ({
        SIEMissingFieldsErrorArrayState: [
          ...prevState.SIEMissingFieldsErrorArrayState,
          SIEOrgNoError,
        ],
      }));
    }

    if (!isSIEOrgNameExist) {
      this.setState((prevState) => ({
        SIEMissingFieldsErrorArrayState: [
          ...prevState.SIEMissingFieldsErrorArrayState,
          "Orgnization Name (#FNAMN)",
        ],
      }));
    }

    if (!isSIEFinancialYearExist) {
      this.setState((prevState) => ({
        SIEMissingFieldsErrorArrayState: [
          ...prevState.SIEMissingFieldsErrorArrayState,
          SIEFinancialYearError,
        ],
      }));
    }

    if (SIEFinancialYearArray.length > 0) {
      this.setState({
        // sliderValue: SIEFinancialYearArray.length - 1,
        financialYearArrayState: SIEFinancialYearArray,
      });
    }

    if (!isSIEOrgNoExist || !isSIEOrgNameExist || !isSIEFinancialYearExist) {
      this.setState({
        organizationNumber: "",
        companyName: "",
        // sliderValue: "",
        financialYearArrayState: "",
      });
    } else {
      this.setState({
        visible: false,
        showProceedButton: false,
        disableCompanyNo: true,
      });

      this.insertSRUReportHeader();
    }
    companyInformation.values = companyInformationValue;
  };

  componentDidMount() {
    const { isNavigated } = this.state;
    this.setState({
      disableCompanyNo: false,
    });

    if (!isNavigated) {
      setTimeout(() => {
        localStorage.setItem("navigateTo", "");
        localStorage.setItem("navigateToGUID", "");
      }, 1000);
    }
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (userSelectedLanguage == "sw") {
      localeLang = "sv";
    } else if (userSelectedLanguage == "en") {
      localeLang = "en";
    }
    setupLocales(localeLang);
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    let {
      companyInformation,
      sessionDetails,
      currentPageName,
      sideBarReducer,
      readOnly,
    } = this.props;
    currentPageName.values = "Company Information";
    localStorage.setItem("currentPageName", "Company Information");
    // let setCurrentPageNameInLs = ["Company Information"]
    // localStorage.setItem("currentPageName", setCurrentPageNameInLs);

    localData = localStorage.getItem("rebelSkoolUser");
    localObject = localData && JSON.parse(localData);
    if (localObject) {
      this.setState({
        newUserUserId: localObject.userID,
        newUserEmailId: localObject.emailID,
      });
      if (localObject.userName == "") {
        this.setState({ showUserNamePasswordDialogBox: true });
      }
    }

    // sideBarGreenTickPages = [];
    // sideBarGreenTickPages.push("My Dashboard");
    // let updatePossibleNavigationPagesListString = JSON.stringify(
    //   sideBarGreenTickPages
    // );
    // localStorage.setItem(
    //   "possibleAccessablePages",
    //   updatePossibleNavigationPagesListString
    // );

    let navigatedFromDashboardFYs = [];

    let navigateToReportGUID = localStorage.getItem("navigateToGUID");
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    sruReportHeaderRequestObj.reportGUID = currentReportGUIDFromUrl;
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data.paymentEntryNo != null) {
            responsePaymentEntryNo = response.data.paymentEntryNo;
          }
          let responseData = response.data;
          if (responseData.reportID != null) {

    //CurrentYear
    if(responseData.currentYear != '') {
      this.setState({currentYear:responseData.currentYear})
      this.formatDateAndCreateFinancialYearArray(responseData.currentYear)
      sruReportHeaderRequestObj.currentYear = responseData.currentYear
    }

    //Forms
            if (responseData.forms != null) {
              let formSplit = responseData.forms.split(",");
              let formFilter = this.SRUForm.filter((item) =>
                formSplit.includes(item.name)
              );
              this.setState({ SelectedSRUForm2: formFilter });
            }

    //Declaration Year
            // let cyResponse = response.data.currentYear;
            // let yearPortion = { name: cyResponse };
            this.setState({ selectedSRUYearQuarterPortion: responseData.fiscalYearPortion });

            // if (cyResponse) {
            //   let splittedCYResponse = cyResponse.split(" ");
            //   let splittedCYRepYear = splittedCYResponse[1].split("-")[0];
            //   let yearName = { name: splittedCYRepYear };
            //   this.setState({ selectedSRUYear: yearName });

     //   //Generate quarters
            //   if (splittedCYRepYear != "" && splittedCYRepYear != null) {
            //     axios
            //       .post(
            //         "/SRUHeader/generateQuarterPeriods?year=" +
            //         splittedCYRepYear
            //       )
            //       .then((response) => {
            //         let respData = response.data;

            //         formattedRanges = respData.map((range, index) => {
            //           return { name: `P${index + 1}: ${range}` };
            //         });
            //         this.SRUYearQuarterPortion = formattedRanges;
            //         this.setState({ reRender: true });
            //       });
            //   }
            // }
          }

          if (responseData.reportID != null) {
            let reportAccessReq = {
              reportId: responseData.reportID,
            };
            axios
              .post(
                "/SRUHeader/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {
                  case 2:
                    linkSharedUser = 2; //Write
                    break;
                  case 3:
                    linkSharedUser = 3; //Read Only
                    break;
                  case 4:
                    linkSharedUser = 4; //No Access
                    break;
                }

                if (responseData.packageEntryNo > 0) {
                  readOnly.values = true;
                }

                if (responseData.postalCode == 0) {
                  this.setState({ postalCode: "" });
                } else {
                  this.setState({ postalCode: responseData.postalCode });
                }
                this.setState({
                  organizationNumber: responseData.registrationNo,
                  companyName: responseData.companyName,
                  postalCode: responseData.postalCode,
                  city: responseData.city,
                  uploadedSIEFile: responseData.sIEFile,
                });

                let navigatedResponseObj = {
                  organizationnumber: responseData.registrationNo,
                  organizationname: responseData.companyName,
                  postalcode: responseData.postalCode,
                  city: responseData.city,
                  financialYear: navigatedFromDashboardFYs,
                };
                sruReportHeaderRequestObj.registrationNo =
                  responseData.registrationNo;
                sruReportHeaderRequestObj.companyName =
                  responseData.companyName;
                sruReportHeaderRequestObj.postalCode = responseData.postalCode;
                sruReportHeaderRequestObj.city = responseData.city;
                sruReportHeaderRequestObj.companyName =
                  responseData.companyName;

                companyInformation.values = navigatedResponseObj;

                axios
                  .get(
                    "/SRU/getCompletedPages?reportId=" + responseData.reportID,
                    userSessionAuthKeyReturnValue
                  )
                  .then((completedStatementTypesResponse) => {
                    sideBarReducer.values =
                      completedStatementTypesResponse.data;
                    sideBarGreenTickPages = [];
                    sideBarGreenTickPages =
                      completedStatementTypesResponse.data;
                    sideBarGreenTickPages.push(
                      "My Dashboard",
                      "Company Information"
                    );
                      let getPossibleAccessablePages = localStorage.getItem(
                        "possibleAccessablePages"
                      );
                      let getPossibleAccessablePagesArray = JSON.parse(
                        getPossibleAccessablePages
                      );
                      let updatePossibleNavigationPagesListString =
                      JSON.stringify(getPossibleAccessablePagesArray);
                    localStorage.setItem(
                      "possibleAccessablePages",
                      updatePossibleNavigationPagesListString
                    );
                    this.updatePossibleAccessPages();
                    this.setState({
                      reRender: true,
                    });
                  });
              })
              .catch((error) => {
                let noAccessURL =
                  "/income-declaration-2/" +
                  currentReportGUIDFromUrl +
                  "/no-access";
                this.props.history.push(noAccessURL);
              });
          } else {
          }
          this.setState({ currentReportID: response.data.reportID });
        });
    } else {
      axios.get("/user/saveAnonymousUser").then((response) => {
        if (response.data) {
          let anonymousUser = {
            ["User-Session-Auth-Key"]: response.data,
          };
          localStorage.setItem("rebelSkoolUser", JSON.stringify(anonymousUser));
          userSessionAuthKeyReturnValue = setUserSessionAuthKey();
        }
      });
    }

    if (companyInformation.values != undefined) {
      this.setState({
        organizationNumber: companyInformation.values.organizationnumber,
        companyName: companyInformation.values.organizationname,
        postalCode: companyInformation.values.postalcode,
        city: companyInformation.values.city,
        financialYearArrayState: companyInformation.values.financialYear,
      });
    }
  }

  formatDateAndCreateFinancialYearArray(currentYearParameter) {
    let respDataCurrentYear = currentYearParameter && currentYearParameter.split(" - ");
    let fromDate = respDataCurrentYear[0];
    let toDate = respDataCurrentYear[1];

    fromDate = new Date(fromDate);
    toDate = new Date(toDate);

   this.setState({currentFromDate:fromDate, currentToDate: toDate})


  }
  updatePossibleAccessPages() {
    let getPossibleAccessablePages = localStorage.getItem(
      "possibleAccessablePages"
    );
    let getPossibleAccessablePagesArray = JSON.parse(
      getPossibleAccessablePages
    );

    getPossibleAccessablePagesArray = JSON.parse(getPossibleAccessablePages);

    if (getPossibleAccessablePagesArray == null) {
      let possibleNavigationPagesList = ["My Dashboard", "Company Information"];
      let possibleNavigationPagesListString = JSON.stringify(
        possibleNavigationPagesList
      );
      localStorage.setItem(
        "possibleAccessablePages",
        possibleNavigationPagesListString
      );
    } else if (
      getPossibleAccessablePagesArray &&
      getPossibleAccessablePagesArray.length &&
      getPossibleAccessablePagesArray.length <= 2
    ) {
      let possibleNavigationPagesList = ["My Dashboard", "Company Information"];
      let possibleNavigationPagesListString = JSON.stringify(
        possibleNavigationPagesList
      );
      localStorage.setItem(
        "possibleAccessablePages",
        possibleNavigationPagesListString
      );
    }
  }

  resetCompanyInforState() {
    this.setState({
      organizationNumber: "",
      companyName: "",
      postalCode: "",
      city: "",
    });
  }

  sruOrganizationNoOnComplete(e) {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    this.setState({
      organizationNumber: e.value,
    });
    let organizationNoValue = e.value;
    if (organizationNoValue != "") {
      sruReportHeaderRequestObj.registrationNo = organizationNoValue;
    }
    if (userSessionAuthKeyReturnValue) {
      axios
        .get("/organization/get/" + e.value, userSessionAuthKeyReturnValue)
        .then((response) => {
          if (response.data != "") {
            this.setState({
              organizationNumber: response.data.organizationnumber,
              companyName: response.data.organizationname,
              postalCode: response.data.postalcode,
              city: response.data.city,
            });
            sruReportHeaderRequestObj.registrationNo =
              response.data.organizationnumber;
            sruReportHeaderRequestObj.companyName =
              response.data.organizationname;
            sruReportHeaderRequestObj.postalCode = response.data.postalcode;
            sruReportHeaderRequestObj.city = response.data.city;
            sruReportHeaderRequestObj.forms = "INK2,INK2R,INK2S";
          }

          this.insertSRUReportHeader();
        });
      this.updatePossibleAccessPages();
    }
  }

  sruOrganizationNoOnChange(e) {
    if (e.value == "") {
      this.resetCompanyInforState();
    }
  }

  companyNameOnChange(e) {
    this.setState({
      companyName: e.target.value,
    });
  }

  companyNameOnBlur(e) {
    let companyNameValue = e.target.value;
    if (companyNameValue != "") {
      sruReportHeaderRequestObj.companyName = companyNameValue;
      this.insertSRUReportHeader();
      this.updatePossibleAccessPages();
    }
  }

  PostalcodeOnChange(e) {
    this.setState({
      postalCode: e.target.value,
    });
  }

  postalCodeOnBlur(e) {
    const { companyInformation } = this.props;
    let postalCodeValue = e.target.value;
    if (postalCodeValue != "") {
      sruReportHeaderRequestObj.postalCode = postalCodeValue;
      // companyInformation.values.postalcode = postalCodeValue;
      this.insertSRUReportHeader();
      this.updatePossibleAccessPages();
    }
  }

  cityOnChange(e) {
    this.setState({
      city: e.target.value,
    });
  }

  cityOnBlur(e) {
    const { companyInformation } = this.props;
    let cityValue = e.target.value;
    sruReportHeaderRequestObj.city = cityValue;
    // companyInformation.values.city = cityValue;
    this.insertSRUReportHeader();
    this.updatePossibleAccessPages();
  }
  sruDateOnBlur(e,isFromDate) {
    const { calendarDateHardCodedValue } = this.state;
    dataKeyIn = "";
    dateValue = '';
    if (calendarDateHardCodedValue != "") {
      if (calendarDateHardCodedValue.length == 6) {
      var year = calendarDateHardCodedValue.substr(0, 2);
      var month = calendarDateHardCodedValue.substr(2, 2);
      var date = calendarDateHardCodedValue.substr(4, 4);
      var newdate = "20" + year;
      newdate = newdate + "-" + month + "-" + date;
      dateValue = new Date(newdate);
    } else if (calendarDateHardCodedValue.length == 8) {
      if (calendarDateHardCodedValue.includes("-")) {
        let splittedFormat = calendarDateHardCodedValue.split("-");
        var year = "20" + splittedFormat[0];
        var month = splittedFormat[1];
        var date = splittedFormat[2];
        var newdate = year + "-" + month + "-" + date;

        dateValue = new Date(newdate);
      } else {
        var year = calendarDateHardCodedValue.substr(0, 4);
        var month = calendarDateHardCodedValue.substr(4, 2);
        var date = calendarDateHardCodedValue.substr(6, 5);
        var newdate = year;
        newdate = newdate + "-" + month + "-" + date;
        dateValue = new Date(newdate);
      }
    }
  }
    const isValidDate = moment(dateValue, "YYYY-MM-DD", true).isValid();
    if (isValidDate) {
      if (isFromDate) {
        currentYearObj = {
          from: dateValue,
          to: moment(dateValue).add(11, "months").endOf("month").toDate(),
        };
      }
      if (!isFromDate) {
        currentYearObj.to = dateValue;
      }
      console.log(dateValue,"#####!!")

      let fromDateFormatted = moment(currentYearObj.from).format("YYYY-MM-DD");
      let toDateFormatted = moment(currentYearObj.to).format("YYYY-MM-DD");

      let combinedYear = fromDateFormatted + " - " + toDateFormatted; 

      sruReportHeaderRequestObj.currentYear = combinedYear;
      // this.insertSRUReportHeader();

    }
    this.setState({reRender:true})
  }

  sruDateOnChange(dateEvent,isFromDate) {
    const {currentFromDate,currentToDate} = this.state;
    if (dateEvent.value != null) {
      if (isFromDate) {
        let fromDate = dateEvent.value;
        let toDate = moment(dateEvent.value).add(11, "months").endOf("month").toDate();
        currentYearObj = { from: fromDate, to: toDate };
        this.setState({currentFromDate:fromDate,currentToDate:toDate})
      } else {
        this.setState({currentToDate:dateEvent.value})
        currentYearObj.to  = dateEvent.value;
      }
      
      let fromDateFormatted = moment(currentYearObj.from).format("YYYY-MM-DD");
      let toDateFormatted = moment(currentYearObj.to).format("YYYY-MM-DD");

      let combinedYear = fromDateFormatted + " - " + toDateFormatted; 

      sruReportHeaderRequestObj.currentYear = combinedYear;
      this.insertSRUReportHeader();
      
    } else {
      dataKeyIn = dataKeyIn + "" + dateEvent.originalEvent.nativeEvent.data;
      dataKeyIn = dataKeyIn.replace("null", "");
      this.setState({
        calendarDateHardCodedValue: dataKeyIn,
      });
    }
    this.setState({
      reRender: true,
    });
  }

  insertSRUReportHeader() {
    console.log(sruReportHeaderRequestObj,"!!!!!!SRU")
    let { companyInformation, sessionDetails } = this.props;
    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/SRUHeader/insertReportHeader",
          sruReportHeaderRequestObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => { 
          axios
            .get(
              "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
              userSessionAuthKeyReturnValue
            )
            .then((headerResponse) => {
              let responseData = headerResponse.data;
              if(responseData.fiscalYearPortion != null) {

                this.setState({ selectedSRUYearQuarterPortion: responseData.fiscalYearPortion });
                if(responseData.currentYear != '') {
                  this.setState({currentYear:responseData.currentYear})
                  this.formatDateAndCreateFinancialYearArray(responseData.currentYear)
                  sruReportHeaderRequestObj.currentYear = responseData.currentYear
                }
              
              }
            })
            this.setState({reRender:true})
        });
    }
  }

  navigateToNextPages() {
    const {
      organizationNumber,
      companyName,
      postalCode,
      city,
      selectedSRUYear,
      selectedSRUYearQuarterPortion,
      SelectedSRUForm2,
    } = this.state;

    let { companyInformation, sessionDetails, login, sideBarReducer } =
      this.props;
    let isIncomplete = false;

    if (organizationNumber == "") {
      this.setState({
        isOrganizationNoMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isOrganizationNoMissing: false,
      });
    if (companyName == "") {
      this.setState({
        isOrganizationNameMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isOrganizationNameMissing: false,
      });

    if (postalCode == "" || postalCode == 0 || postalCode == null) {
      this.setState({
        isPostalCodeMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isPostalCodeMissing: false,
      });

    if (city == "") {
      this.setState({
        isCityMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isCityMissing: false,
      });

    // if (
    //   selectedSRUYear == "" ||
    //   selectedSRUYear == 0 ||
    //   selectedSRUYear == null
    // ) {
    //   this.setState({
    //     selectedSRUYearMissing: true,
    //   });
    //   isIncomplete = true;
    // } else
    //   this.setState({
    //     selectedSRUYearMissing: false,
    //   });

    if (
      selectedSRUYearQuarterPortion == "" ||
      selectedSRUYearQuarterPortion == 0 ||
      selectedSRUYearQuarterPortion == null
    ) {
      this.setState({
        selectedSRUYearQuarterPortionMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        selectedSRUYearQuarterPortionMissing: false,
      });

    // SelectedSRUForm;

    if (
      SelectedSRUForm2 == "" ||
      SelectedSRUForm2 == 0 ||
      SelectedSRUForm2 == null
    ) {
      this.setState({
        SelectedSRUFormMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        SelectedSRUFormMissing: false,
      });

    if (isIncomplete) {
      this.toast.show({
        severity: "error",
        summary: TranslationFile.IncompleteText[userSelectedLanguage],
        detail: TranslationFile.FillMandatryFields[userSelectedLanguage],
        life: 5000,
      });
    } else {
      if (SelectedSRUForm2 && SelectedSRUForm2.length > 0) {
        let sortArray =
          SelectedSRUForm2 && [...SelectedSRUForm2].sort((a, b) => a.id - b.id);
        let uuid = sessionDetails.sessionDetails.values.uuid;
        this.props.history.push(
          "/income-declaration-2/" + uuid + "/" 
          + sortArray[0].name
        );
      }
    }
  }

  dialogBoxOnClick = (e) => {
    if (e.defaultPrevented) {
      this.setState({
        showProceedButton: false,
      });
    }
  };

  toggleMask = () => {
    this.setState((prevState) => ({
      isMasked: !prevState.isMasked,
    }));
  };

  removeFile = () => {
    this.setState({
      SIEMissingFieldsErrorArrayState: [],
      showProceedButton: false,
    });
  };

  selectFile = (selectedFileCallback) => {
    selectedFile = selectedFileCallback.files[0];
    let selectedFileFormat = selectedFileCallback.files[0].name
      .split(".")
      .pop();

    if (selectedFileFormat != "se" && selectedFileFormat != "txt") {
      this.toast.show({
        severity: "error",
        summary: TranslationFile.InvalidFileFormat[userSelectedLanguage],
        detail: TranslationFile.FileFormatWarning[userSelectedLanguage],
        life: 6000,
      });

      this.setState({
        showProceedButton: false,
      });
    } else {
      this.setState({
        showProceedButton: true,
        SIEMissingFieldsErrorArrayState: [],
      });
    }
  };

  proceedBtnOnClick() {
    const { isProceedButtonClicked } = this.state;
    let { companyInformation, sessionDetails, login, sideBarReducer } =
      this.props;

    this.setState({
      SIEMissingFieldsErrorArrayState: [],
      showProceedButton: false,
    });

    if (!isProceedButtonClicked) {
      this.setState({
        isProceedButtonClicked: true,
      });

      var file = selectedFile;
      const url = "/annualReport/uploadSIEFile";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("reportGUID", currentReportGUIDFromUrl);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "User-Session-Auth-Key":
            userSessionAuthKeyReturnValue.headers["User-Session-Auth-Key"],
        },
      };
      axios.post(url, formData, config).then((sieUploadedResponse) => {
        if (sieUploadedResponse.data) {
          axios
            .get(
              "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
              userSessionAuthKeyReturnValue
            )
            .then((headerResponse) => {
              let headerResponseData = headerResponse.data;
              let navigatedFromDashboardFYs = [];
              this.setState({
                organizationNumber: headerResponseData.registrationNo,
                companyName: headerResponseData.companyName,
                postalCode:
                  headerResponseData.postalCode == 0
                    ? ""
                    : headerResponseData.postalCode,
                city: headerResponseData.city,
                visible: false,
                isProceedButtonClicked: false,
                uploadedSIEFile: headerResponseData.sIEFile,
                currentReportID: headerResponseData.reportID,
              });

              let navigatedResponseObj = {
                organizationnumber: headerResponseData.registrationNo,
                organizationname: headerResponseData.companyName,
                postalcode:
                  headerResponseData.postalCode == 0
                    ? ""
                    : headerResponseData.postalCode,
                city: headerResponseData.city,
                financialYear: navigatedFromDashboardFYs,
              };

              sruReportHeaderRequestObj.companyName =
                headerResponseData.companyName;
              sruReportHeaderRequestObj.registrationNo =
                headerResponseData.registrationNo;
              sruReportHeaderRequestObj.postalCode =
                headerResponseData.postalCode == 0
                  ? ""
                  : headerResponseData.postalCode;
              sruReportHeaderRequestObj.city = headerResponseData.city;

              companyInformation.values = navigatedResponseObj;

              //currentReportGUIDFromUrl
              if (headerResponseData.registrationNo != "") {
              }
            });
        }
      });
    }
  }

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    let sessionFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      this.props.history.push(annualReport + sessionFromLS + link);
    }
    this.setState({
      reRender: true,
    });
  };

  newUserSaveButton() {
    const {
      newUserEmailId,
      newUserUserId,
      newUserName,
      newUserPassword,
      newUserConfirmPassword,
    } = this.state;
    if (
      newUserName != "" &&
      newUserPassword != "" &&
      newUserConfirmPassword != "" &&
      userSessionAuthKeyReturnValue
    ) {
      let userDetails = {
        userID: newUserUserId,
        userName: newUserName,
        password: newUserPassword,
      };
      axios
        .post(
          "/user/updateUsernamePasswordForUserID",
          userDetails,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let userEmailPasswordDetails = {
              emailID: newUserEmailId,
              password: newUserPassword,
            };
            axios
              .post("/user/loginAndGetUser", userEmailPasswordDetails)
              .then((response) => {
                const userAuthKey = response.headers["user-session-auth-key"];
                response.data["User-Session-Auth-Key"] = userAuthKey;
                localStorage.setItem(
                  "rebelSkoolUser",
                  JSON.stringify(response.data)
                );
                this.setState({ showUserNamePasswordDialogBox: false });
                window.location.reload(false);
              });
          }
        });
    }
  }

  redirectToHomePage() {
    window.location.assign("/#/income-declaration-2");
  }

  downloadSIEFile() {
    const { currentReportID } = this.state;
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/downloadSIEFile?reportID=" + currentReportID,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          response["Access-Control-Expose-Headers"] = "Content-Disposition";
          const filename =
            response.headers["content-disposition"].split("filename=")[1];
          const blob = new Blob([response.data]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  convertFormObjToStringAndInsert(formValue) {
    if (formValue.length == 0) {
      sruReportHeaderRequestObj.forms = "";
      this.insertSRUReportHeader();
    } else {
      let sortArray = [...formValue].sort((a, b) => a.id - b.id);
      let onlyFormName = "";
      sortArray.forEach((val, idx) => {
        if (idx == 0) {
          onlyFormName = onlyFormName + val.name;
        } else {
          onlyFormName = onlyFormName + "," + val.name;
        }
      });
      if (onlyFormName != null && onlyFormName != "") {
        sruReportHeaderRequestObj.forms = onlyFormName;
        this.insertSRUReportHeader();
      }
    }
  }

  selectSRUForm = (e) => {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    let { SelectedSRUForm2, currentReportID } = this.state;
    let formValue = e.value;

    //If the user is adding the form option for the first time, let him add the option and insert it into the DB
    if (SelectedSRUForm2 == null) {
      this.convertFormObjToStringAndInsert(formValue);
      this.setState({ SelectedSRUForm2: e.value });
    }
    //How to findout if the user is adding or removing the option?
    //If the user is already having form value, compare the length of the state with the e.value

    //If the length of the state is greater and the e.value is lesser then the user is removing the option
    if (SelectedSRUForm2 != null && SelectedSRUForm2.length > formValue.length) {
      let result = SelectedSRUForm2.filter(
        (o1) => !e.target.value.some((o2) => o1.id === o2.id)
      );
      this.setState({ deleteForm: result });
      //Call an API to check if there is any entry record in the Lines Table
      let SRULines = {
        reportID: currentReportID,
        statementType: result[0].id,
      };
      if (userSessionAuthKeyReturnValue) {
        axios
          .get(
            "/SRULines/checkSRULinesExist?reportId=" +
            currentReportID +
            "&statementType=" +
            result[0].id,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              //If there is an entry, a dialog box should open and ask that the user has a entry in the lines table.
              this.setState({ showRemoveConfimationDialog: true });
            } else {
              //If there is no entry in the Lines Table, let the user delete the statementType
              this.setState({ SelectedSRUForm2: e.value });
            }
          });
      }
    }

    //If the length of the state is lesser and the e.value is greater then the user is adding the option    
    if (SelectedSRUForm2 != null && SelectedSRUForm2.length < formValue.length) {
      this.convertFormObjToStringAndInsert(formValue);
      this.setState({ SelectedSRUForm2: e.value });
    }
  };
  deleteSelectedForm() {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    const { deleteForm, currentReportID, SelectedSRUForm2 } = this.state;
    //Yes => Delete the entries in the Lines Table and JSONLines Table and Close the dialog box
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/SRULines/deleteStatementTypeFromReport?reportId=" +
          currentReportID +
          "&statementType=" +
          deleteForm[0].id,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            //The entries are deleted in Lines & LinesJSON table
            this.toast.show({
              severity: "error",
              summary: "Form removed",
              detail:
                "The " +
                deleteForm[0].name +
                " form data is removed from the Report",
              life: 5000,
            });
            this.setState({ showRemoveConfimationDialog: false });
            //Remove the deleteForm obj from the selectedForm state and set it in the selectedForm state
            let remainingForms = SelectedSRUForm2.filter(
              (item1) =>
                !deleteForm.some(
                  (item2) => item1.id === item2.id && item1.name === item2.name
                )
            );
            this.setState({ SelectedSRUForm2: remainingForms });
            this.convertFormObjToStringAndInsert(remainingForms);
          }
        });
    }
  }
  deleteFormFooter = (
    //No => Close the dialog box
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => this.setState({ showRemoveConfimationDialog: false })}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => this.deleteSelectedForm()}
      />
    </React.Fragment>
  );

  selectSRUForm2 = (e) => {
    this.setState({ SelectedSRUForm2: e.value });
    let formValue = e.value;
    this.convertFormObjToStringAndInsert(formValue);
  };
  handleSelectAll = (e) => {
    if (e.target.checked) {
      this.setState({ SelectedSRUForm2: this.SRUForm });
    } else {
      this.setState({ SelectedSRUForm2: [] });
    }
  };

  selectAllHeader() {
    const { SelectedSRUForm2 } = this.state;
    return (
      <div className="flex align-items-center">
        <input
          type="checkbox"
          className="p-checkbox"
          id="checkbox_dropdown_addForm"
          onChange={this.handleSelectAll}
          checked={SelectedSRUForm2 && SelectedSRUForm2.length === this.SRUForm.length}
        />
        <label className="dropdwon_selectAll" >{TranslationFile.SelectAll[userSelectedLanguage]}</label>
      </div>
    );
  }

  render() {
    let { readOnly, showWIPStatus } = this.props;
    const {
      isMasked,
      organizationNumber,
      companyName,
      postalCode,
      city,
      isCityMissing,
      isOrganizationNameMissing,
      isOrganizationNoMissing,
      isPostalCodeMissing,
      selectedSRUYearMissing,
      selectedSRUYearQuarterPortionMissing,
      SelectedSRUFormMissing,
      SIEMissingFieldsErrorArrayState,
      showProceedButton,
      visible,
      showUserNamePasswordDialogBox,
      newUserPassword,
      newUserConfirmPassword,
      newUserName,
      isProceedButtonClicked,
      uploadedSIEFile,
      selectedSRUYearQuarterPortion,
      currentToDate,
      currentFromDate
    } = this.state;

    let pageUnderWIP = false;
    if (showWIPStatus.values != undefined) {
      if (showWIPStatus.values) {
        pageUnderWIP = true;
      } else {
        pageUnderWIP = false;
      }
    }

    return (
      <div>
        {linkSharedUser !== 4 ? (
          <div>
            <div>
              <Toast
                ref={(el) => {
                  this.toast = el;
                }}
                className="SRU-companyInfoErrorToast"
                style={{ marginTop: "34%" }}
              ></Toast>

              <SRUNavBarHeader
                callBack={this.callback}
                isAppPortal={true}
                isHomePage={false}
              />
              <div
                className={
                  pageUnderWIP
                    ? "SRU-companyInfoSideBarWithWIP"
                    : "SRU-companyInfoSideBar"
                }
              >
                <Sidebar
                  children="Company Information"
                  sideBarStatus={this.sideBarStatus.bind(this)}
                  sideBarCallback={this.sideBarCallback.bind(this)}
                  selectedForms={this.state.SelectedSRUForm2}
                />
              </div>
              <div className="SRU-CIcontentParentDiv">
                <div className="SRU-CIcontentParentDiv">
                  <div className="createSRULabel">
                    {TranslationFile.CreateSRU[userSelectedLanguage]}
                  </div>
                  <div
                    className="SRU-upload-second-div"
                    style={{ pointerEvents: readOnly.values && "none" }}
                  >
                    <div className="SRU-companyIndiImportInsideDiv">
                    <Button
                          // label="Import SIE"
                          label={
                            TranslationFile.IncomestateImportText1[
                            userSelectedLanguage
                            ]
                          }
                          className="SRU-import-btn-upload"
                          icon="pi pi-file-import"
                          onClick={() => this.setState({ visible: true })}
                          disabled={linkSharedUser == 3}
                        />
                      {/* {uploadedSIEFile != undefined &&
                        uploadedSIEFile != "" &&
                        uploadedSIEFile != null ? (
                        <Dropdown as={ButtonGroup}>
                          <Button
                            label={
                              TranslationFile.IncomestateImportText1[
                              userSelectedLanguage
                              ]
                            }
                            icon="pi pi-file-import"
                            onClick={() => this.setState({ visible: true })}
                            disabled={
                              linkSharedUser == 3 || responsePaymentEntryNo > 0
                            }
                          />

                          <Dropdown.Toggle
                            split
                            id="SRU-dropdown-split-basic"
                            className="SRU-import-sie-dropdown"
                          />

                          <Dropdown.Menu className="SRU-download-sie-dropdown">
                            <Dropdown.Item
                              onClick={() => this.downloadSIEFile()}
                            >
                              <span
                                className="pi pi-download"
                                style={{ marginRight: "5px" }}
                              ></span>
                              Download SIE File
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <Button
                          // label="Import SIE"
                          label={
                            TranslationFile.IncomestateImportText1[
                            userSelectedLanguage
                            ]
                          }
                          className="SRU-import-btn-upload"
                          icon="pi pi-file-import"
                          onClick={() => this.setState({ visible: true })}
                          disabled={linkSharedUser == 3}
                        />
                      )} */}
                    </div>

                    <Dialog
                      header={
                        TranslationFile.IncomestateImportText1[
                        userSelectedLanguage
                        ]
                      }
                      blockScroll
                      visible={visible}
                      onHide={() =>
                        this.setState({
                          visible: false,
                          SIEMissingFieldsErrorArrayState: [],
                        })
                      }
                      id="SRU-import-dialog"
                      style={{ width: "50vw" }}
                      onClick={this.dialogBoxOnClick}
                      className="SRU-import_file-sub_title"
                    >
                      {isProceedButtonClicked ? (
                        <div className="SRU-sieUploadLoadingDiv">
                          <center>
                            <ProgressSpinner
                              style={{
                                width: "50px",
                                height: "40px",
                                top: "118px",
                              }}
                              className="SRU-companyInfoProceedSpinner"
                            />
                          </center>
                        </div>
                      ) : (
                        <div>
                          <FileUpload
                            maxFileSize={10000000000}
                            name="demo[]"
                            emptyTemplate={
                              <p className="SRU-import_file_m-0">
                                {
                                  TranslationFile.IncomestateImportDag[
                                  userSelectedLanguage
                                  ]
                                }
                              </p>
                            }
                            onSelect={this.selectFile}
                            multiple={false}
                            onRemove={this.removeFile}
                          />

                          {showProceedButton != "" && (
                            <div className="SRU-proceed-btn-div">
                              <button
                                onClick={this.proceedBtnOnClick.bind(this)}
                              >
                                {isProceedButtonClicked ? (
                                  <ProgressSpinner
                                    style={{ width: "50px", height: "40px" }}
                                    className="SRU-companyInfoProceedSpinner"
                                  />
                                ) : (
                                  TranslationFile.IncomestateImportbtnPrceed[
                                  userSelectedLanguage
                                  ]
                                )}
                              </button>
                            </div>
                          )}

                          {SIEMissingFieldsErrorArrayState.length > 0 && (
                            <div>
                              <h4>
                                {
                                  TranslationFile.IncomestateImportbtnError[
                                  userSelectedLanguage
                                  ]
                                }
                              </h4>
                              {SIEMissingFieldsErrorArrayState.map((i, idx) => {
                                return (
                                  <li className="SRU-SIEFileErrorList">{i}</li>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </Dialog>
                  </div>
                  <div className="SRU-info-year-container">
                    <Fieldset
                      style={{ pointerEvents: readOnly.values && "none" }}
                      className="SRU-company_fieldset1"
                      // legend="Fill in the company details below"
                      legend={
                        TranslationFile.IncomestateHeader1[userSelectedLanguage]
                      }
                    >
                      <div className="SRU-info-number-name">
                        <Row className="SRU-info-row">
                          <Col className="SRU-info-col">
                            <label
                              htmlFor="OrganizationNumber"
                              className="SRU-companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyOrgNo[
                                userSelectedLanguage
                                ]
                              }
                              {/* OrganizationNumber */}
                              <p className="SRU-companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputMask
                              className={
                                isOrganizationNoMissing
                                  ? "missingFieldsStyle"
                                  : "info-org-no"
                              }
                              mask="999999-9999"
                              value={organizationNumber}
                              onComplete={(e) =>
                                this.sruOrganizationNoOnComplete(e)
                              }
                              onChange={(e) =>
                                this.sruOrganizationNoOnChange(e)
                              }
                              useGrouping={false}
                            // disabled={
                            //   disableCompanyNo ||
                            //   linkSharedUser == 3 ||
                            //   responsePaymentEntryNo > 0
                            // }
                            />
                          </Col>
                          <Col className="SRU-info-col">
                            <label
                              htmlFor="CompanyName"
                              className="SRU-companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyOrgName[
                                userSelectedLanguage
                                ]
                              }
                              <p className="SRU-companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputText
                              className={
                                isOrganizationNameMissing
                                  ? "missingFieldsStyle"
                                  : "info-org-name"
                              }
                              value={companyName}
                              onChange={(e) => this.companyNameOnChange(e)}
                              onBlur={(e) => this.companyNameOnBlur(e)}
                            // disabled={linkSharedUser == 3}
                            />
                          </Col>
                        </Row>
                        <Row className="SRU-info-row">
                          <Col className="SRU-info-col">
                            <label
                              htmlFor="PostalCode"
                              className="SRU-companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyPostalcode[
                                userSelectedLanguage
                                ]
                              }
                              <p className="SRU-companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputNumber
                              inputId={
                                isPostalCodeMissing
                                  ? "withoutgroupingIncomplete"
                                  : "withoutgrouping"
                              }
                              value={postalCode}
                              onValueChange={(e) => this.PostalcodeOnChange(e)}
                              onBlur={(e) => this.postalCodeOnBlur(e)}
                              useGrouping={false}
                            // disabled={linkSharedUser == 3}
                            />
                          </Col>
                          <Col className="SRU-info-col">
                            <label
                              htmlFor="City"
                              className="SRU-companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyCity[
                                userSelectedLanguage
                                ]
                              }
                              <p className="SRU-companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputText
                              className={
                                isCityMissing
                                  ? "missingFieldsStyle"
                                  : "info-org-city"
                              }
                              value={city}
                              onChange={(e) => this.cityOnChange(e)}
                              onBlur={(e) => this.cityOnBlur(e)}
                              disabled={linkSharedUser == 3}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Fieldset>
                    <Fieldset
                      className="SRU-company_fieldset1"
                      legend="Financial Year details "
                    >
                      <div style={{ pointerEvents: readOnly.values && "none" }}>
                        <div className="SRU-Financial-div">
                          <Row className="SRU-current-year-text-row">
                            <Col> STARTING DATE </Col>
                            <Col> ENDING DATE </Col>
                          </Row>
                          <Row className="SRU-current-year-row">
                            <Col>
                              <Calendar
                              value={currentFromDate}
                                id="icon"
                                onChange={(e) => {
                                  this.sruDateOnChange(e,true);
                                }}
                                onBlur={(e) => this.sruDateOnBlur(e,true)}
                                showIcon
                                dateFormat="yy-mm-dd"
                                placeholder={
                                  TranslationFile.YearFormat[
                                  userSelectedLanguage
                                  ]
                                }
                                locale={localeLang}
                                className="SRU-calendar-date"
                              />
                            </Col>
                            <Col>
                              <Calendar
                              value={currentToDate}
                                id="icon"
                                onChange={(e) => {
                                  this.sruDateOnChange(e,false);
                                }}
                                onBlur={(e) => this.sruDateOnBlur(e,false)}
                                showIcon
                                dateFormat="yy-mm-dd"
                                placeholder={
                                  TranslationFile.YearFormat[
                                  userSelectedLanguage
                                  ]
                                }
                                locale={localeLang}
                                className="SRU-calendar-date"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="SRU-Financial-div">
                          <Row>
                            {/* <Col>Last day of the fiscal year between</Col> */}
                            <Col>{TranslationFile.lastDayofFYIbtwn[userSelectedLanguage]}</Col>
                            {/* <Col>Add forms</Col> */}
                            <Col>{TranslationFile.AddForms[userSelectedLanguage]}</Col>
                          </Row>
                          <Row>
                            <Col>
                            <InputText value={this.state.selectedSRUYearQuarterPortion}/>
                              {/* <Dropdown
                                className={
                                  selectedSRUYearQuarterPortionMissing
                                    ? "missingFieldsStyle"
                                    : "info-sru-year"
                                }
                                id="sru_yearbtwn_dropdown"
                                value={this.state.selectedSRUYearQuarterPortion}
                                options={this.SRUYearQuarterPortion}
                                onChange={this.onSRUYearQuarterPortionChange}
                                optionLabel="name"
                                placeholder="Select Year Portion"
                              // disabled={
                              //   this.state.selectedSRUYear == null && true
                              // }
                              /> */}
                            </Col>
                            <Col>
                              <MultiSelect
                                className={
                                  SelectedSRUFormMissing
                                    ? "missingFieldsStyle"
                                    : "SruFormMultiSelect"
                                }
                                value={this.state.SelectedSRUForm2}
                                options={this.SRUForm}
                                onChange={this.selectSRUForm2}
                                optionLabel="name"
                                placeholder="Select a Form"
                                display="chip"
                                // disabled={
                                //   (this.state.selectedSRUYear == null ||
                                //     this.state.selectedSRUYearQuarterPortion ==
                                //     null) &&
                                //   true
                                // }
                                panelHeaderTemplate={this.selectAllHeader()}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <br></br>
                      {/* <br></br> */}
                      <center>
                        <Button
                          label="Next"
                          aria-label="year-btn-save"
                          onClick={() => this.navigateToNextPages()}
                          className="SRU-year-btn-save"
                          style={{
                            width: "100px",
                            height: "44px",
                            fontSize: "1.2rem",
                            marginTop: "2%",
                          }}
                        />
                      </center>
                    </Fieldset>
                  </div>
                </div>
                <ScrolltoTop />

                <Footer_contact_tab
                  sideBar={this.state.sideBarStatus}
                  isCorpPortal={false}
                  isHomePage={true}
                />
              </div>

              <div>
                <Dialog
                  header="New User? Login Here"
                  visible={showUserNamePasswordDialogBox}
                  onHide={() =>
                    this.setState({ showUserNamePasswordDialogBox: false })
                  }
                  blockScroll
                  style={{ width: "30vw" }}
                  className="SRU-newUserDialogBox"
                >
                  <div>
                    <div style={{ padding: "20px 30px" }}>
                      <div>
                        <div style={{ textAlign: "left" }}>
                          {
                            TranslationFile.IncomestateYearName[
                            userSelectedLanguage
                            ]
                          }
                        </div>
                        <InputText
                          value={newUserName}
                          onChange={(e) => {
                            this.setState({ newUserName: e.target.value });
                          }}
                        />
                      </div>
                      <br></br>
                      <div>
                        <div style={{ textAlign: "left" }}>
                          {
                            TranslationFile.IncomestateYearPassword[
                            userSelectedLanguage
                            ]
                          }
                        </div>
                        <div className="p-inputgroup">
                          {/* Password Input */}
                          <input
                            type={isMasked ? "password" : "text"}
                            value={newUserPassword}
                            onChange={(e) => {
                              this.setState({
                                newUserPassword: e.target.value,
                              });
                            }}
                            className="p-inputtext p-component"
                          />

                          {/* Toggle Icon */}
                          <span
                            className="p-inputgroup-addon"
                            onClick={this.toggleMask}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={
                                isMasked ? "pi pi-eye-slash" : "pi pi-eye"
                              }
                              style={{ fontSize: "1.2rem" }}
                            ></i>
                          </span>
                        </div>
                        {/* <Password
                          value={newUserPassword}
                          onChange={(e) => {
                            this.setState({ newUserPassword: e.target.value });
                          }}
                          feedback={false}
                        /> */}
                      </div>
                      <br></br>

                      <div>
                        <div style={{ textAlign: "left" }}>
                          {
                            TranslationFile.IncomestateYearConPassword[
                            userSelectedLanguage
                            ]
                          }
                        </div>
                        <div className="p-inputgroup">
                          {/* Password Input */}
                          <input
                            type={isMasked ? "password" : "text"}
                            value={newUserConfirmPassword}
                            onChange={(e) => {
                              this.setState({
                                newUserConfirmPassword: e.target.value,
                              });
                            }}
                            className="p-inputtext p-component"
                          />

                          {/* Toggle Icon */}
                          <span
                            className="p-inputgroup-addon"
                            onClick={this.toggleMask}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={
                                isMasked ? "pi pi-eye-slash" : "pi pi-eye"
                              }
                              style={{ fontSize: "1.2rem" }}
                            ></i>
                          </span>
                        </div>

                        {/* <Password
                          value={newUserConfirmPassword}
                          onChange={(e) => {
                            this.setState({
                              newUserConfirmPassword: e.target.value,
                            });
                          }}
                          feedback={false}
                        /> */}
                      </div>
                    </div>
                    <br></br>
                    <center>
                      <Button
                        icon="pi pi-check"
                        onClick={() => this.newUserSaveButton()}
                      >
                        {
                          TranslationFile.IncomestateYearsave[
                          userSelectedLanguage
                          ]
                        }
                      </Button>
                    </center>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <center>
              <div className="no-access-div">
                <table>
                  <tr>
                    <td>
                      <i
                        className="pi pi-times-circle"
                        style={{ color: "red", fontSize: "2rem" }}
                      ></i>
                    </td>
                    <td>
                      <label style={{ paddingLeft: "10px", color: "red" }}>
                        {
                          TranslationFile.IncomestateYearNoAccess[
                          userSelectedLanguage
                          ]
                        }
                      </label>
                    </td>
                  </tr>
                </table>
                <Button onClick={() => this.redirectToHomePage()}>
                  {
                    TranslationFile.IncomestateYearGoToHome[
                    userSelectedLanguage
                    ]
                  }
                </Button>
              </div>
            </center>
          </div>
        )}
        <Dialog
          visible={this.state.showRemoveConfimationDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Remove the Page from your Report?"
          blockScroll
          modal
          footer={this.deleteFormFooter}
          onHide={() => this.setState({ showRemoveConfimationDialog: false })}
        >
          <div className="confirmation-content">
            <span>
              <span>
                {" "}
                Are you sure you want to delete the selected Form from your
                report?
              </span>
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(SRUCompanyInfo);
