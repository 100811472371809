import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { Toast } from "primereact/toast";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import Autocomplete from "react-autocomplete";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import "./NotesNew.css";

let userSelectedLanguage,
  userSessionAuthKeyReturnValue = setUserSessionAuthKey(),
  currentReportGUIDFromUrl = "",
  notesTemplateReqArray = [],
  financialYearDatesForTemplate = [],
  notesTemplateResponseData = "",
  fyToUpdateInColumnArray = [],
  finalLineSumExistingHeaderList = [],
  lastAccordionKey = "3 - UPPLYSNINGAR TILL BALANSRÄKNINGEN";
let steps = [
  {
    content: TranslationFile.TourGuide_AddNewColumn_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".NotesAddNewColumnLbl",
    title: TranslationFile.AddNewRecord[userSelectedLanguage],
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_DeleteRecord_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#notesDeleteHeader",
    title: TranslationFile.DeleteField[userSelectedLanguage],
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_Add_NewNotes_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#notesAddNewBtn",
    title: TranslationFile.AddNewNotes[userSelectedLanguage],
    disableBeacon: true,
    delay: 500,
  },
];

let checkBoxLineNos = {},
  disableLineNos = [];

export default function Notes() {
  const [sideBarStatus, setSideBarStatus] = useState(true);
  const [notesTemplateValue, setNotesTemplateValue] = useState("");
  const [notesRenderValue, setNotesRenderValue] = useState("");
  const [activeIndex, setActiveIndex] = useState([0]);
  const [run, setRun] = useState(false);
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const [render, setRender] = useState(false);
  const [items, setItems] = useState(null);
  const [addNewDialogBoxVisible, setAddNewDialogBoxVisible] = useState(false);
  const [addNotesHeaderDropdown, setAddNotesHeaderDropdown] = useState(null);
  // const [addNotesLinesDropdown, setAddNotesLinesDropdown] = useState(null);
  const [addNotesSubHeaderDropdown, setAddNotesSubHeaderDropdown] = useState(
    []
  );
  const [selectedAddNotesHeader, setSelectedAddNotesHeader] = useState(null);
  const [selectedAddNotesSubHeader, setSelectedAddNotesSubHeader] =
    useState(null);
  const [selectedAddNotesSubHeaderLines, setSelectedAddNotesSubHeaderLines] =
    useState([]);
  const [finalLineSum, setFinalLineSum] = useState("");

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);

  const [confirmToastVisible, setConfirmToastVisible] = useState(false);
  const [colConfirmToastVisible, setColConfirmToastVisible] = useState(false);
  const [selectedDeleteRow, setSelectedDeleteRow] = useState(null);

  const toast = useRef(null);
  const confirmToast = useRef(null);
  const history = useHistory();

  const sideBarStatusFnc = (e) => {
    setSideBarStatus(e);
  };

  const tourStatus = (e) => {
    setRun(e);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((headerResponse) => {
          let headerResponseData = headerResponse.data;

          if (
            headerResponseData != "" &&
            headerResponseData.currentYear != null
          ) {
            financialYearDatesForTemplate = [];
            let financialYearDateObj = {
              validFromDate: moment(
                headerResponseData.currentYear.slice(0, 10)
              ).format("YYYY-MM-DD"),
              validToDate: moment(
                headerResponseData.currentYear.slice(13, 23)
              ).format("YYYY-MM-DD"),
              statementType: 1,
              statementSubType: 2,
              header: "2 - UPPLYSNINGAR TILL RESULTATRÄKNINGEN",
            };

            financialYearDatesForTemplate.push(financialYearDateObj);

            let getJsonLines = {
              guid: currentReportGUIDFromUrl,
              pageName: "Notes",
            };

            axios
              .post(
                "/annualReportLine/getLinesJson",
                getJsonLines,
                userSessionAuthKeyReturnValue
              )
              .then((linesJsonResponse) => {
                let linesJsonResponseData = linesJsonResponse.data;

                if (
                  linesJsonResponseData != "" &&
                  linesJsonResponseData.reportID != null &&
                  linesJsonResponseData.notes != "" &&
                  linesJsonResponseData.notes != null
                ) {
                  Object.keys(JSON.parse(linesJsonResponseData.notes)).map(
                    (i, idx) => {
                      setActiveIndex((prevState) => [...prevState, idx]);
                    }
                  );

                  let notesRenderJson = JSON.parse(linesJsonResponseData.notes);

                  Object.keys(notesRenderJson).map((heading, idx) => {
                    let headerLines = notesRenderJson[heading];

                    // let checkBoxLines = headerLines.filter((obj) =>
                    //   obj.templateColumn2.includes("CheckBox")
                    // );

                    let checkBoxLines = headerLines.filter(
                      (obj) =>
                        obj.templateColumn2 &&
                        obj.templateColumn2.includes("CheckBox")
                    );

                    checkBoxLines.forEach((obj) => {
                      if (obj["isCheckBox"] == undefined)
                        obj["isCheckBox"] = true;
                    });

                    let fromToDateHeaderLines = headerLines.filter(
                      (obj) =>
                        obj.templateColumn2 == "FromToDate" ||
                        obj.templateColumn2 == "ToDate"
                    );

                    fromToDateHeaderLines &&
                      fromToDateHeaderLines.length &&
                      fromToDateHeaderLines.map((i, idx) => {
                        if (i.column3 != "" || i.column3 != 0)
                          prepareFinancialYearDatesForTemplate(i, 3, heading);

                        if (i.column4 != "" || i.column4 != 0)
                          prepareFinancialYearDatesForTemplate(i, 4, heading);

                        if (i.column5 != "" || i.column5 != 0)
                          prepareFinancialYearDatesForTemplate(i, 5, heading);
                      });
                  });

                  setRender(!render);

                  setNotesTemplateValue(notesRenderJson);
                  const updatedObj = updateObject(notesRenderJson);

                  delete notesRenderJson.financialYearList;

                  setNotesRenderValue(notesRenderJson);

                  let responseJsonValue = JSON.parse(
                    linesJsonResponseData.notes
                  );

                  financialYearDatesForTemplate =
                    responseJsonValue.financialYearList;
                  delete responseJsonValue.financialYearList;
                  // setNotesRenderValue(responseJsonValue);
                  let lastObjectValues =
                    getLastKeyValueLineNo(responseJsonValue);
                  getSuggestionLinesByParentLineNo(
                    lastObjectValues,
                    true,
                    false,
                    false
                  );
                } else {
                  notesTemplateReqArray = [];

                  axios
                    .post(
                      "/user/getAnnualReportTemplateDetails",
                      financialYearDatesForTemplate,
                      userSessionAuthKeyReturnValue
                    )
                    .then((notesTemplateResponse) => {
                      notesTemplateResponseData = notesTemplateResponse.data;
                      Object.keys(notesTemplateResponseData).map((i, idx) => {
                        setActiveIndex((prevState) => [...prevState, idx]);
                        let lines = notesTemplateResponseData[i];
                        // lines.forEach((item) => {
                        //   item.statementType = "Notes";
                        // });

                        let checkBoxLines = lines.filter((obj) =>
                          obj.templateColumn2.includes("CheckBox")
                        );

                        checkBoxLines.forEach((obj) => {
                          return (obj["isCheckBox"] = true);
                        });

                        lines = lines.filter(
                          (obj) =>
                            obj.templateColumn2 == "FromToDate" ||
                            obj.templateColumn2 == "ToDate"
                        );

                        lines.forEach((item) => {
                          item.column2 = item.financialYearRange;
                          item.column3 = "";
                          item.column4 = "";
                          item.column5 = "";
                          item.column6 = "";
                        });
                      });
                      setNotesTemplateValue(notesTemplateResponseData);
                      const updatedObj = updateObject(
                        notesTemplateResponseData
                      );
                      delete updatedObj.financialYearList;
                      setNotesRenderValue(updatedObj);
                      let lastObjectValues = getLastKeyValueLineNo(updatedObj);
                      getSuggestionLinesByParentLineNo(
                        lastObjectValues,
                        true,
                        false,
                        false
                      );
                    });
                }
              });
          }
        });
    }
  }, []);

  useEffect(() => {
    if (addNewDialogBoxVisible) {
      // Disable scrolling on the body when the dialog is visible
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling again when the dialog is hidden
      document.body.style.overflow = "auto";
    }

    // Cleanup the style on unmount or when dialog visibility changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [addNewDialogBoxVisible]);

  // Function to dynamically update the object
  const updateObject = (obj) => {
    // Get all keys of the object
    const keys = Object.keys(obj);

    // Iterate over the keys and create a new object with updated values
    const updatedObj = keys.reduce((acc, key, idx) => {
      // If it's the third key (idx === 2), keep only the first element of the array
      if (idx === 2) {
        acc[key] = [obj[key][0]]; // Keep only the first object in the array
      } else {
        acc[key] = obj[key]; // Keep the full array for other keys
      }
      return acc;
    }, {});

    return updatedObj;
  };

  const sideBarCallback = (value) => {
    if (value) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const callback = (annualReport, session, link, status) => {
    if (status) {
      history.push(annualReport + session + link);
    }
  };

  const pageNavigation = (navigateToPageName) => {
    const annualReport = "/annualreport/";

    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );

    let possibleAccessablePagesListLSArray = JSON.parse(
      possibleAccessablePagesListLSString
    );

    if (!possibleAccessablePagesListLSArray.includes("Management Statement")) {
      possibleAccessablePagesListLSArray.push(
        "Company Information",
        "Income Statement",
        "Balance Sheet",
        "Notes",
        "Management Statement"
      );

      let updatePossibleNavigationPagesListString = JSON.stringify(
        possibleAccessablePagesListLSArray
      );

      localStorage.setItem(
        "possibleAccessablePages",
        updatePossibleNavigationPagesListString
      );
    }

    history.push(
      annualReport + currentReportGUIDFromUrl + "/" + navigateToPageName
    );
  };

  const custAddNewColumn = (
    line,
    headerLines,
    tabHeading,
    fyArray,
    columnNo
  ) => {
    console.log(
      line,
      ">>>",
      headerLines,
      ">>",
      tabHeading,
      ">>",
      fyArray,
      ">>",
      columnNo
    );

    let linesToUpdate = headerLines.filter(
      (item) =>
        item.financialYearHeading == line.financialYearHeading &&
        item.lineLevel != 1
    );

    if (columnNo == 3) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Can not add more than 2 financial year",
        life: 3000,
      });
    } else {
      switch (columnNo) {
        case 2:
          let fyHeading = linesToUpdate.filter(
            (item) => item.templateColumn2 == "ToDate"
          );
          fyHeading &&
            fyHeading.length &&
            fyHeading.map((i, idx) => {
              let currentFy = i.column2.split(" - ");
              let currentFromDate = currentFy[0];
              let currentToDate = currentFy[1];

              currentFromDate = moment(currentFromDate).subtract(
                12,
                "month"
              )._d;
              currentToDate = moment(currentFromDate)
                .add(11, "months")
                .endOf("month")
                .toDate();

              currentFromDate = moment(currentFromDate).format("YYYY-MM-DD");
              currentToDate = moment(currentToDate).format("YYYY-MM-DD");

              let prevFy = currentFromDate + " - " + currentToDate;
              line.column3 = prevFy;

              console.log(prevFy, "!! PREV FY", line);
            });
          break;
        case 3:
          break;
      }
    }

    setNotesRenderValue(notesRenderValue);
    insertLines(notesRenderValue);
    setRender(!render);
  };

  const addNewColumnOnClick = (headerLines, tabHeading, fyArray) => {
    let kk = financialYearDatesForTemplate.filter(
      (item) => item.header == tabHeading
    );
    let fyLength = kk.length;

    if (fyLength < 2) {
      let lines = notesRenderValue[tabHeading];
      let fromToHeaderFromLines = lines.filter(
        (obj) => obj.templateColumn2 == "FromToDate"
      );

      if (fromToHeaderFromLines.length == 0) {
        fromToHeaderFromLines = lines.filter(
          (obj) => obj.templateColumn2 == "ToDate"
        );
      }

      let fromToDateHeader = headerLines.filter(
        (obj) => obj.templateColumn2 == "FromToDate"
      );

      if (fromToDateHeader.length == 0) {
        fromToDateHeader = headerLines.filter(
          (obj) => obj.templateColumn2 == "ToDate"
        );
      }

      let fromToDateLength = fromToDateHeader.length;

      if (fromToDateLength > 0) {
        let lastFyIdx = fyLength - 1;
        let lastNotesFy = kk[lastFyIdx];

        let prevFromDate,
          prevToDate = "";

        prevFromDate = moment(lastNotesFy.validFromDate).subtract(
          12,
          "month"
        )._d;
        prevToDate = moment(prevFromDate)
          .add(11, "months")
          .endOf("month")
          .toDate();

        prevFromDate = moment(prevFromDate).format("YYYY-MM-DD");
        prevToDate = moment(prevToDate).format("YYYY-MM-DD");

        let prevFy = prevFromDate + " - " + prevToDate;
        fromToHeaderFromLines &&
          fromToHeaderFromLines.length &&
          fromToHeaderFromLines.map((i, idx) => {
            switch (fyLength) {
              case 0:
                i.column2 = prevFy;
                break;

              case 1:
                addNewColumnInObj(3, prevFy, -1, i);
                break;

              case 2:
                addNewColumnInObj(4, prevFy, -2, i);
                break;

              case 3:
                addNewColumnInObj(5, prevFy, -3, i);
                break;

              case 4:
                addNewColumnInObj(6, prevFy, -4, i);
                break;
            }
          });

        let prevFyObj = {
          statementType: 1,
          validFromDate: prevFromDate,
          validToDate: prevToDate,
          isCustomLine: true,
          header: tabHeading,
          statementSubType:
            headerLines.filter((item) => item.templateColumn2 == "FromToDate")
              .length > 0
              ? 2
              : 3,
        };
        financialYearDatesForTemplate.push(prevFyObj);

        let dateHeaders = headerLines.filter(
          (obj) => obj.header && obj.lineNo != obj.parentLineNo
        );

        dateHeaders &&
          dateHeaders.length &&
          dateHeaders.map((i, idx) => {
            let fyToUpdateInColumnObj = {
              columnNo: financialYearDatesForTemplate.length + 1,
              financialYear: prevFy,
              toLineNo: i.lineNo,
              reportGUID: currentReportGUIDFromUrl,
            };

            fyToUpdateInColumnArray.push(fyToUpdateInColumnObj);
          });

        axios
          .post(
            "/annualReportDetails/updateFinancialYearInColumnInNotes",
            fyToUpdateInColumnArray,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
            }
          });

        setRender(!render);
        updateLinesJsonTable(notesRenderValue);
      }
    } else {
      // alert("Limit Reached");
      toast.current.show({
        severity: "info",
        summary: "Info",
        // detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        detail: "Can not add more than 2 financial year",
        life: 3000,
      });
    }
  };

  const prepareFinancialYearDatesForTemplate = (line, idx, heading) => {
    let fyValue = line["column" + idx].split(" - ");
    let fyObj = {
      statementType: 1,
      validFromDate: fyValue[0],
      validToDate: fyValue[1],
      isCustomLine: true,
      header: heading,
      statementSubType: line.templateColumn2 == "FromToDate" ? 2 : 3,
    };
    financialYearDatesForTemplate.push(fyObj);
  };
  const accordionHeader = (headerLines, tabHeading) => {
    let tabHeadingObj = headerLines[0];
    let linesArray = headerLines.filter(
      (obj) =>
        obj.parentLineNo == tabHeadingObj.lineNo &&
        obj.lineNo != tabHeadingObj.lineNo
    );

    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "100%", marginTop: "7px" }}
        >
          <div className="header-content">
            <span>{tabHeadingObj.name}</span>
            <span
              className="custom-text"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the accordion tab from toggling
                e.preventDefault(); // Prevent the page from scrolling
              }}
            >
              {tabHeadingObj.column2 == "Add New Column" &&
                linesArray.length > 0 &&
                tabHeading != lastAccordionKey && (
                  <label className="notesAddNewColumnLbl">
                    <span
                      onClick={() =>
                        addNewColumnOnClick(
                          headerLines,
                          tabHeading,
                          financialYearDatesForTemplate
                        )
                      }
                    >
                      + Add new column
                    </span>
                  </label>
                )}
            </span>
          </div>
        </Col>
      </Row>
    );
  };

  const deleteBtnClick = (heading, line) => {
    let headerLines = notesRenderValue[heading];
    let index = headerLines.findIndex((item) => item.lineNo === line.lineNo);
    headerLines.splice(index, 1);

    setRender(!render);
    axios
      .post(
        "/annualReportLine/deleteCustomLine",
        line,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        if (response.data) {
          updateLinesJsonTable(notesRenderValue);
        }
      });
  };

  const addBtnClick = (heading, line) => {
    let newLine = { ...line };
    newLine.column1 = "";
    newLine.name = "";
    newLine.placeHolder = "";
    newLine.lineNo = line.lineNo + 1;
    newLine.isCustomLine = true;
    newLine.column2 = "";

    let headerLines = notesRenderValue[heading];

    let index = headerLines.findIndex((item) => item.lineNo === line.lineNo);
    headerLines.splice(index + 1, 0, newLine);

    axios
      .get(
        "/siteOwner/getSuggestionListByParentLineNo?parentLineNo=" +
          line.parentLineNo,
        userSessionAuthKeyReturnValue
      )
      .then((suggestionResponse) => {
        if (suggestionResponse.data.length > 0) {
          let suggestions = suggestionResponse.data;

          let suggestionsList = [];
          suggestions &&
            suggestions.length &&
            suggestions.map((i, idx) => {
              let suggestionObj = {
                id: idx + 1,
                label: i.column1,
                lineNo: i.lineNo,
              };
              suggestionsList.push(suggestionObj);
            });

          setItems(suggestionsList);
        } else setItems([]);
      });
  };

  const textAreaOnBlur = (e) => {
    insertLines(notesRenderValue);
  };

  const textAreaOnChange = (heading, line, e) => {
    let linesArrayFilteredByHeader = notesRenderValue[heading];

    let lineObjArrayFilteredByLineNo = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo == line.lineNo
    );

    lineObjArrayFilteredByLineNo.forEach((item) => {
      item.column1 = e;
    });

    setRender(!render);
  };

  const dynamicInputNumberOnBlur = (
    heading,
    line,
    columnNo,
    custEnteredValue
  ) => {
    custEnteredValue = formatInputNumber(custEnteredValue);

    let linesArrayFilteredByHeader = notesRenderValue[heading];
    let lineObjArrayFilteredByLineNo = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo == line.lineNo
    );

    lineObjArrayFilteredByLineNo.forEach((item) => {
      switch (columnNo) {
        case 2:
          item.column2 = custEnteredValue;
          updateLinesObjWithCustomKey(item, 0, custEnteredValue);
          break;

        case 3:
          item.column3 = custEnteredValue;
          updateLinesObjWithCustomKey(item, -1, custEnteredValue);
          break;

        // case 4:
        //   item.column4 = custEnteredValue;
        //   updateLinesObjWithCustomKey(item, -2, custEnteredValue);
        //   break;

        // case 5:
        //   item.column5 = custEnteredValue;
        //   updateLinesObjWithCustomKey(item, -3, custEnteredValue);
        //   break;
      }
    });

    let lineSum = linesArrayFilteredByHeader.filter(
      (obj) => obj.sumLineNos != ""
    );

    lineSum &&
      lineSum.length &&
      lineSum.map((i, idx) => {
        if (i.sumLineNos !== undefined) {
          let rangeArray = [];

          if (i.lineLevel == 5) {
            let rangeStringArray = i.sumLineNos.split(",");
            rangeArray = rangeStringArray.map((item) => parseInt(item));
          } else {
            let lineNoRange = i.sumLineNos.split("-");
            let start = parseInt(lineNoRange[0]);
            let end = parseInt(lineNoRange[1]);

            for (let j = start; j <= end; j += 1000) {
              rangeArray.push(j);
            }
          }

          let linesToSum = linesArrayFilteredByHeader.filter((obj) =>
            rangeArray.includes(obj.lineNo)
          );

          let totalValueForLineSum = {
            column2: 0,
            column3: 0,
          };

          i.column2 = "";
          i.column3 = "";

          linesToSum.forEach((item) => {
            totalValueForLineSum.column2 += item.column2 || 0; // Avoid NaN if column2 is missing
            totalValueForLineSum.column3 += item.column3 || 0; // Avoid NaN if column3 is missing
          });

          i.column2 = totalValueForLineSum.column2;
          i.column3 = totalValueForLineSum.column3;
        }
      });

    setRender(!render);
    insertLines(notesRenderValue);
  };

  const insertLines = (linesObj) => {
    // Merge all values from the object into one array
    delete linesObj.financialYearList;
    let mergedArray = [].concat(...Object.values(linesObj));
    mergedArray.forEach((item) => {
      item["reportGUID"] = currentReportGUIDFromUrl;
      item["nameTemp"] = item.name;
      item.statementType = "Notes";

      if (
        item.column2 != undefined &&
        typeof item.column2 != "number" &&
        item.column2.includes("CheckBox(")
      ) {
        item.column2 = "";
      }

      if (
        item.isHeader &&
        item.lineLevel == 2 &&
        item.column2 == "FromToDate"
      ) {
        //   item.column2=currentfinancialYearRange
        item.column2 = "";
        item.column3 = "";
        item.column4 = "";
        item.column5 = "";
        item.column6 = "";

        financialYearDatesForTemplate &&
          financialYearDatesForTemplate.length &&
          financialYearDatesForTemplate.map((fy, fyIdx) => {
            item["column" + (fyIdx + 2)] =
              financialYearDatesForTemplate[fyIdx].validFromDate +
              " - " +
              financialYearDatesForTemplate[fyIdx].validToDate;
          });
      }
    });
    //
    // mergedArray.forEach(
    //   (item) => (item.isCustomLine && item.statementSubType = "Balance Statement")
    // );

    mergedArray.forEach((item) => {
      if (item.isCustomLine) {
        item.statementSubType = "Balance Statement";
      }
    });

    if (mergedArray.length > 0) {
      axios
        .post(
          "/annualReportLine/insertLines",
          mergedArray,
          userSessionAuthKeyReturnValue
        )
        .then((insertLinesResponse) => {
          if (insertLinesResponse.data) {
            updateLinesJsonTable(linesObj);
          }
        });
    }
  };

  const formatInputNumber = (custEnteredValue) => {
    custEnteredValue = custEnteredValue.replaceAll(",", "");
    let spacesRemoved = custEnteredValue.replaceAll(/\s/g, "");
    const firstChar = spacesRemoved.charAt(0);
    let minusArray = ["-", "−", "‑", "−"];

    if (minusArray.includes(firstChar)) {
      const newStr = spacesRemoved.slice(1);
      custEnteredValue = -1 * newStr;
    } else {
      custEnteredValue = Number(spacesRemoved);
    }

    return custEnteredValue;
  };
  const staticInputNumberOnBlur = (heading, line, custEnteredValue) => {
    custEnteredValue = formatInputNumber(custEnteredValue);

    let linesArrayFilteredByHeader = notesRenderValue[heading];
    let lineObjArrayFilteredByLineNo = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo == line.lineNo
    );
    lineObjArrayFilteredByLineNo.forEach((item) => {
      item.column2 = custEnteredValue;
    });
    setRender(!render);
    insertLines(notesRenderValue);
  };

  const addNewColumnInObj = (columnNo, prevFy, financialYear, i) => {
    // i.column3 = prevFy;
    i["column" + columnNo] = prevFy;
    i["financialYearRange"] = prevFy;
    i["financialYearValue"] = financialYear;
    i["lineAmount"] = "";
    i["nameTemp"] = i.name;
  };
  const updateLinesObjWithCustomKey = (
    item,
    financialYear,
    custEnteredValue
  ) => {
    let currentfinancialYearRange =
      financialYearDatesForTemplate[0].validFromDate +
      " - " +
      financialYearDatesForTemplate[0].validToDate;

    item["financialYearRange"] = currentfinancialYearRange;
    item["financialYearValue"] = financialYear;
    item["lineAmount"] = custEnteredValue;
    item["nameTemp"] = item.name;

    return item;
  };

  const updateLinesJsonTable = (linesObj) => {
    linesObj["financialYearList"] = financialYearDatesForTemplate;
    let updateJsonTableObj = {
      pageName: "Notes",
      guid: currentReportGUIDFromUrl,
      json: JSON.stringify(linesObj),
    };

    axios
      .post(
        "/annualReportLine/updateLinesJsonTable",
        updateJsonTableObj,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {});
  };

  const highlightMatchedText = (label, value) => {
    // Split the label by the matched value and wrap the match with a span for styling
    const parts = label.split(new RegExp(`(${value})`, "i"));
    return parts.map((part, index) =>
      part.toLowerCase() === value.toLowerCase() ? (
        <span
          key={index}
          style={{ backgroundColor: "#2a55a5", color: "white" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleSelect = (selectedValue, line, heading) => {
    let selectedSuggestionOption = items.filter(
      (obj) => obj.label == selectedValue
    )[0];
    line.column1 = selectedValue;
    line.lineNo = selectedSuggestionOption.lineNo;

    setRender(!render);
  };

  const inputMaskOnBlur = (e, columnNo, line, headerLines) => {
    let userInput = e.target.value;

    if (userInput == "") {
      let fyToUpdateInColumnObj = {
        columnNo: columnNo,
        financialYear: "",
        toLineNo: line.lineNo,
        reportGUID: currentReportGUIDFromUrl,
      };

      axios
        .post(
          "/annualReportDetails/updateFinancialYearInColumnInNotes",
          [fyToUpdateInColumnObj],
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            // updateLinesJsonTable(notesRenderValue);
            insertLines(notesRenderValue);
          }
        });
    }
  };
  const onComplete = (e, columnNo, line) => {
    let userInput = e.value;

    switch (columnNo) {
      case 2:
        line.column2 = userInput;
        break;
      case 3:
        line.column3 = userInput;
        break;
      case 4:
        line.column4 = userInput;
        break;
      case 5:
        line.column5 = userInput;
        break;
    }

    let fyToUpdateInColumnObj = {
      columnNo: columnNo,
      financialYear: userInput,
      toLineNo: line.lineNo,
      reportGUID: currentReportGUIDFromUrl,
    };

    let fyToUpdateInColumnArray = [];

    axios
      .post(
        "/annualReportDetails/updateFinancialYearInColumnInNotes",
        [fyToUpdateInColumnObj],
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        if (response.data) {
          updateLinesJsonTable(notesRenderValue);
        }
      });
  };

  const dynamicFinancialYearInputMask = (
    line,
    columnNo,
    headerLines,
    heading,
    statementSubType,
    lineIdx
  ) => {
    return (
      <Col xl={3} lg={3} md={3} sm={3} xs={3} className="AddNewColumnCustLbl">
        {line.templateColumn2 == "ToDate" ? (
          <InputMask
            value={line["column" + columnNo]}
            mask="9999-99-99"
            placeholder="YYYY-DD-MM"
            slotChar="yyyy-dd-mm"
            className="notesToDateFyInputMask"
            onComplete={(e) => onComplete(e, columnNo, line)}
            onBlur={(e) => inputMaskOnBlur(e, columnNo, line)}
            // disabled={columnNo == 2 && lineIdx > 1 && true}
            // disabled={lineIdx > 1 && true}
          />
        ) : (
          <InputMask
            value={line["column" + columnNo]}
            mask="9999-99-99 - 9999-99-99"
            placeholder="YYYY-DD-MM - YYYY-DD-MM"
            slotChar="yyyy-dd-mm - yyyy-dd-mm"
            className="notesFyInputMask"
            onComplete={(e) => onComplete(e, columnNo, line)}
            onBlur={(e) => inputMaskOnBlur(e, columnNo, line)}
            // disabled={columnNo == 2 && lineIdx > 1 && true}
            // disabled={lineIdx > 1 && true}
          />
        )}

        {columnNo > 1 && (
          <>
            <i
              id="notesDeleteHeader"
              className="fa fa-times-circle"
              aria-hidden="true"
              title="Remove Header"
              onClick={() =>
                deleteCustomColumn(
                  line,
                  columnNo,
                  headerLines,
                  heading,
                  statementSubType
                )
              }
            ></i>
          </>
        )}

        {columnNo == 2 && line.column3 == "" && statementSubType == 3 ? (
          <>
            <label className="notesAddNewColumnRowLbl">
              <span
                onClick={() =>
                  custAddNewColumn(
                    line,
                    headerLines,
                    heading,
                    financialYearDatesForTemplate,
                    columnNo
                  )
                }
              >
                + Add new column
              </span>
            </label>
          </>
        ) : (
          columnNo == 3 &&
          line.column3 != "" &&
          statementSubType == 3 && (
            <>
              <label className="notesAddNewColumnRowLbl">
                <span
                  onClick={() =>
                    custAddNewColumn(
                      line,
                      headerLines,
                      heading,
                      financialYearDatesForTemplate,
                      columnNo
                    )
                  }
                >
                  + Add new column
                </span>
              </label>
            </>
          )
        )}
      </Col>
    );
  };

  const dynamicFinancialYearInputNumber = (heading, line, columnNo) => {
    let getLastAccordionFyList = financialYearDatesForTemplate.filter(
      (item) => item.header == lastAccordionKey
    );

    let tabHeaderLines = notesRenderValue[heading];

    if (line.financialYearHeading != undefined) {
      // let parentLineObj = tabHeaderLines.filter((item) => item.lineLevel == 2)[0];
      let parentLineObj = tabHeaderLines.filter(
        (item) => item.column1 == line.financialYearHeading
      )[0];

      return (
        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
          <InputNumber
            inputId="integeronly"
            value={line["column" + columnNo]}
            className="notesDynamicColumnsLineInput"
            onBlur={(e) =>
              dynamicInputNumberOnBlur(heading, line, columnNo, e.target.value)
            }
            useGrouping={true}
            locale="sv-SE"
            disabled={line.sumLineNos != "" && line.lineSumRange != "" && true}
          />

          {columnNo == 2 &&
          parentLineObj.column3 == "" &&
          line.lineType !== 5 ? (
            <i
              id="notesTrashIcon"
              className="pi pi-trash"
              onClick={() => customRowDelete(heading, line, columnNo)}
            />
          ) : (
            columnNo == 3 &&
            parentLineObj.column3 != "" &&
            line.lineType !== 5 && (
              <i
                id="notesTrashIcon"
                className="pi pi-trash"
                onClick={() => customRowDelete(heading, line, columnNo)}
              />
            )
          )}

          {columnNo == 2 &&
          parentLineObj.column3 == "" &&
          line.lineType == 5 ? (
            <i
              id="notesCustomSubHeaderLinesDelete"
              className="fa fa-times-circle"
              aria-hidden="true"
              title="Remove Header"
              onClick={() => customRowDelete(heading, line, columnNo)}
            ></i>
          ) : (
            columnNo == 3 &&
            parentLineObj.column3 != "" &&
            line.lineType == 5 && (
              <i
                id="notesCustomSubHeaderLinesDelete"
                className="fa fa-times-circle"
                aria-hidden="true"
                title="Remove Header"
                onClick={() => customRowDelete(heading, line, columnNo)}
              ></i>
            )
          )}
        </Col>
      );
    } else {
      return (
        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
          <InputNumber
            inputId="integeronly"
            value={line["column" + columnNo]}
            className="notesDynamicColumnsLineInput"
            onBlur={(e) =>
              dynamicInputNumberOnBlur(heading, line, columnNo, e.target.value)
            }
            useGrouping={true}
            locale="sv-SE"
            disabled={line.sumLineNos != "" && line.lineSumRange != "" && true}
          />
        </Col>
      );
    }
  };

  const deleteCustomColumn = (
    line,
    columnNo,
    headerLines,
    heading,
    statementSubType
  ) => {
    let columnsToDeleteFromLines = headerLines.filter(
      (item) =>
        item.financialYearHeading == line.financialYearHeading &&
        item.lineLevel != 1
    );

    let selectedRow = {
      columnsToDeleteFromLines: columnsToDeleteFromLines,
      columnNo: columnNo,
    };

    setSelectedDeleteRow(selectedRow);

    console.log(statementSubType, "^^%^");

    if (columnNo == 2 && columnsToDeleteFromLines[0].column3 == "") {
      setColConfirmToastVisible(true);
    } else if (statementSubType == 3) {
      //remove from financialYearDatesForTemplate
      let objToRemoveFromFyList = financialYearDatesForTemplate.filter(
        (item) => item.statementSubType == statementSubType && item.isCustomLine
      );
      let kk = financialYearDatesForTemplate.filter(
        (item) =>
          !objToRemoveFromFyList.some(
            (bbItem) => bbItem.validFromDate === item.validFromDate
          )
      );

      financialYearDatesForTemplate = kk;

      deleteCustomColumnLogic(columnsToDeleteFromLines, columnNo);
    }
  };

  const deleteCustomColumnLogic = (columnsToDeleteFromLines, columnNo) => {
    columnsToDeleteFromLines &&
      columnsToDeleteFromLines.length &&
      columnsToDeleteFromLines.map((i, idx) => {
        switch (columnNo) {
          case 2:
            i.column2 = i.column3;
            i.column3 = "";
            i.column4 = "";
            i.column5 = "";
          case 3:
            i.column3 = i.column4;
            i.column4 = i.column5;
            i.column5 = "";
            break;
          case 4:
            i.column4 = i.column5;
            i.column5 = "";
            break;
          case 5:
            i.column5 = 0;
            break;
        }
      });

    // console.log(columnsToDeleteFromLines, "??");
    // insertLines(notesRenderValue);
    // setRender(!render);
  };

  const addNewNotesOnClick = () => {
    // alert("ADD NEW NOTES");
    setAddNewDialogBoxVisible(true);
    setSelectedAddNotesHeader(null);
    setAddNotesSubHeaderDropdown([]);
    setSelectedAddNotesSubHeader(null);
    setSelectedAddNotesSubHeaderLines([]);
  };

  const getSuggestionLinesByParentLineNo = (
    parentLineNo,
    isHeader,
    isSubHeader,
    isLines
  ) => {
    if (parentLineNo != undefined) {
      axios
        .get(
          "/siteOwner/getSuggestionListByParentLineNo?parentLineNo=" +
            parentLineNo,
          userSessionAuthKeyReturnValue
        )
        .then((suggestionResponse) => {
          if (suggestionResponse.data.length > 0) {
            let suggestions = suggestionResponse.data;
            suggestions.forEach((obj) => (obj["name"] = obj.column1));
            if (isHeader) {
              suggestions.forEach((item) => {
                item["header"] = true;
                item["isHeader"] = true;
              });
              setAddNotesHeaderDropdown(suggestions);
            }

            if (isSubHeader) {
              let subHeaderLines = suggestions.filter(
                (i, idx) => i.lineType == 5
              );
              subHeaderLines.forEach((item) => {
                item["header"] = true;
                item["isHeader"] = true;
                item["isSubHeader"] = true;
              });

              let existingHeaders = notesRenderValue[lastAccordionKey].filter(
                (item) =>
                  item.isHeader && item.lineType == 5 && item.lineLevel == 3
              );

              let removedExistingHeaders = subHeaderLines.filter(
                (item) =>
                  !existingHeaders.some(
                    (bbItem) => bbItem.lineNo === item.lineNo
                  )
              );

              // setAddNotesSubHeaderDropdown(subHeaderLines);
              setAddNotesSubHeaderDropdown(removedExistingHeaders);

              let finalLineSum = suggestions.filter(
                (obj) => obj.lineLevel == 5
              );
              if (finalLineSum != null) {
                setFinalLineSum(finalLineSum);
                // finalLineSumExistingHeaderList()
              }
              if (subHeaderLines.length == 0) {
                setSelectedAddNotesSubHeaderLines(suggestions);
                setSelectedAddNotesSubHeader(null);
              }
            }

            if (isLines) {
              setSelectedAddNotesSubHeaderLines(suggestions);
            }
          }
        });
    }
  };

  const getLastKeyValueLineNo = (obj) => {
    let keys = Object.keys(obj); // Get all keys of the object

    for (let i = keys.length - 1; i >= 0; i--) {
      // Loop through keys from last to first
      let key = keys[i];
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return obj[key][0].lineNo; // Return the key of the array with the last element
      }
    }

    return null; // If no arrays with values are found
  };

  const NewNotesHeaderOnSelect = (e) => {
    setSelectedAddNotesHeader(e.value);
    setSelectedAddNotesSubHeaderLines([]);
    getSuggestionLinesByParentLineNo(e.value.lineNo, false, true, false);
  };

  const NewNotesSubHeaderOnSelect = (e) => {
    setSelectedAddNotesSubHeader(e.value);
    setSelectedAddNotesSubHeaderLines([]);
    getSuggestionLinesByParentLineNo(e.value.lineNo, false, false, true);
  };

  const deleteDialogBoxLines = (line) => {
    let updatedLines = selectedAddNotesSubHeaderLines.filter(
      (item) => item.no !== line.no || item.lineNo !== line.lineNo
    );

    setSelectedAddNotesSubHeaderLines(updatedLines);
  };

  const addNotesBtnOnClick = () => {
    let validLinesCount = selectedAddNotesSubHeaderLines.filter(
      (obj) => obj.lineType == 2
    ).length;
    if (validLinesCount == 0) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "At least 1 line should present",
        life: 3000,
      });
    } else {
      let objsToAdd = [];

      let isToDate = false;

      // selectedAddNotesHeader.forEach((item) => {
      if (selectedAddNotesHeader.column2 === "ToDate") {
        selectedAddNotesHeader.column2 = ""; // Update the value of column2
        if (!isToDate) isToDate = true;
      }
      if (selectedAddNotesHeader.column3 === "ToDate") {
        selectedAddNotesHeader.column3 = ""; // Update the value of column2
        if (!isToDate) isToDate = true;
      }
      if (selectedAddNotesHeader.column4 === "ToDate") {
        selectedAddNotesHeader.column4 = ""; // Update the value of column2
        if (!isToDate) isToDate = true;
      }
      if (selectedAddNotesHeader.column5 === "ToDate") {
        selectedAddNotesHeader.column5 = ""; // Update the value of column2
        if (!isToDate) isToDate = true;
      }
      if (selectedAddNotesHeader.column6 === "ToDate") {
        selectedAddNotesHeader.column6 = ""; // Update the value of column2
        if (!isToDate) isToDate = true;
      }

      setSelectedAddNotesHeader(selectedAddNotesHeader);

      objsToAdd.push(selectedAddNotesHeader);
      if (selectedAddNotesSubHeader != null)
        objsToAdd.push(selectedAddNotesSubHeader);

      let currentFy =
        financialYearDatesForTemplate[0].validFromDate +
        " - " +
        financialYearDatesForTemplate[0].validToDate;

      let prevFyObj = {
        statementType: 1,
        validFromDate: financialYearDatesForTemplate[0].validFromDate,
        validToDate: financialYearDatesForTemplate[0].validToDate,
        isCustomLine: true,
        header: lastAccordionKey,
        statementSubType: 3,
      };

      let getLastAccordionFyList = financialYearDatesForTemplate.filter(
        (item) => item.header == lastAccordionKey
      );

      if (getLastAccordionFyList.length == 0) {
        selectedAddNotesHeader.column2 = isToDate
          ? financialYearDatesForTemplate[0].validFromDate
          : currentFy;
        selectedAddNotesHeader.column3 =
          selectedAddNotesHeader.column3 != ""
            ? selectedAddNotesHeader.column3
            : "";
        selectedAddNotesHeader.column4 = "";
        selectedAddNotesHeader.column5 = "";
        selectedAddNotesHeader.column6 = "";
      } else {
        getLastAccordionFyList &&
          getLastAccordionFyList.length &&
          getLastAccordionFyList.map((fy, fyIdx) => {
            switch (fyIdx) {
              case 0:
                selectedAddNotesHeader.column2 = isToDate
                  ? financialYearDatesForTemplate[0].validFromDate
                  : currentFy;
                selectedAddNotesHeader.column3 =
                  selectedAddNotesHeader.column3 != ""
                    ? selectedAddNotesHeader.column3
                    : "";
                selectedAddNotesHeader.column4 = "";
                selectedAddNotesHeader.column5 = "";
                selectedAddNotesHeader.column6 = "";
                break;

              case 1:
                let prevFromDate,
                  prevToDate = "";

                prevFromDate = moment(
                  getLastAccordionFyList[0].validFromDate
                ).subtract(12, "month")._d;
                prevToDate = moment(prevFromDate)
                  .add(11, "months")
                  .endOf("month")
                  .toDate();

                prevFromDate = moment(prevFromDate).format("YYYY-MM-DD");
                prevToDate = moment(prevToDate).format("YYYY-MM-DD");

                let prevFy = prevFromDate + " - " + prevToDate;
                selectedAddNotesHeader.column3 = isToDate
                  ? financialYearDatesForTemplate[0].validFromDate
                  : prevFy;
                break;
            }
          });
      }

      objsToAdd = objsToAdd.concat(selectedAddNotesSubHeaderLines);

      getLastAccordionFyList = financialYearDatesForTemplate.filter(
        (item) =>
          item.header == lastAccordionKey &&
          item.validFromDate == financialYearDatesForTemplate[0].validFromDate
      );

      if (getLastAccordionFyList.length == 0)
        financialYearDatesForTemplate.push(prevFyObj);

      if (
        !finalLineSumExistingHeaderList.includes(selectedAddNotesHeader.column1)
      ) {
        objsToAdd = objsToAdd.concat(finalLineSum);
        finalLineSumExistingHeaderList.push(selectedAddNotesHeader.column1);
      }

      objsToAdd.forEach((item) => {
        return (item["isCustomLine"] = true);
      });

      let lastKey = lastAccordionKey;

      let existingObj = notesRenderValue[lastAccordionKey];
      let fyHeaderList = existingObj.filter(
        (item) => item.financialYearHeading == objsToAdd[0].column1
      );

      fyHeaderList = fyHeaderList.concat(objsToAdd);

      objsToAdd.forEach(
        (item) =>
          (item["financialYearHeading"] = selectedAddNotesHeader.column1)
      );

      let index = existingObj.findLastIndex(
        (item) =>
          item["financialYearHeading"] === selectedAddNotesHeader.column1
      );

      if (index !== -1 && existingObj[index].lineLevel === 5) {
        existingObj.splice(index, 0, ...objsToAdd);
      }
      existingObj = existingObj.concat(objsToAdd);

      // Use a Set to track duplicates
      let uniqueFinal = existingObj.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.lineNo === value.lineNo)
      );

      uniqueFinal.forEach(
        (obj) =>
          (obj["financialYearHeading"] =
            obj["financialYearHeading"] == undefined
              ? selectedAddNotesHeader.column1
              : obj["financialYearHeading"])
      );

      notesRenderValue[lastKey] = uniqueFinal;

      console.log(
        notesRenderValue,
        "??",
        selectedAddNotesHeader,
        "??",
        selectedAddNotesSubHeader,
        "??",
        addNotesHeaderDropdown,
        "??",
        addNotesSubHeaderDropdown
      );

      let gg = addNotesSubHeaderDropdown.filter(
        (item) => item.lineNo != selectedAddNotesSubHeader.lineNo
      );

      if (gg.length == 0) {
        let oo = addNotesHeaderDropdown.filter(
          (item) => item.lineNo != selectedAddNotesHeader.lineNo
        );
        console.log(gg, "$$>>", oo);
        // setAddNotesHeaderDropdown(oo);
      }

      setAddNewDialogBoxVisible(false);
      insertLines(notesRenderValue);
      setRender(!render);
    }
  };

  const checkBoxOnClick = (e, line, heading) => {
    let checkStatus = e.checked;
    line.isCheckBox = checkStatus;
    setRender(!render);

    let column2Value = line.templateColumn2;
    let numberStringArray = column2Value
      .substring(0, column2Value.length - 1)
      .split("(")[1]
      .split("-");

    let enableLineNos = [];
    if (numberStringArray.length == 1) {
      if (checkStatus) {
        enableLineNos.push(Number(numberStringArray[0]));
      } else {
        disableLineNos.push(Number(numberStringArray[0]));
      }
    } else if (numberStringArray.length > 1) {
      let start = parseInt(numberStringArray[0], 10);
      let end = parseInt(numberStringArray[1], 10);

      let rangeArray = [];
      for (let i = start; i <= end; i += 1000) {
        rangeArray.push(i);
        if (checkStatus) {
          enableLineNos.push(Number(i));
        } else {
          disableLineNos.push(Number(i));
        }
      }
    }

    disableLineNos.push(line.lineNo);
    enableLineNos.push(line.lineNo);

    // disableLineNos = disableLineNos.filter(
    //   (item) => !enableLineNos.includes(item)
    // );

    // enableLineNos = disableLineNos.filter(
    //   (item) => !disableLineNos.includes(item)
    // );

    let headerLine = notesRenderValue[heading];

    if (checkStatus) {
      headerLine.forEach((item) => {
        if (enableLineNos.includes(item.lineNo)) {
          item.isVisible = checkStatus;
        }
      });
    } else {
      headerLine.forEach((item) => {
        if (disableLineNos.includes(item.lineNo)) {
          item.isVisible = checkStatus;
        }
      });
    }

    // disableLineNos = [];

    disableLineNos = disableLineNos.filter(
      (item) => !enableLineNos.includes(item)
    );

    enableLineNos = [];

    // disableLineNos = disableLineNos.filter(
    //   (item) => !enableLineNos.includes(item)
    // );

    // headerLine.forEach((item) => {
    //   if (disableLineNos.includes(item.lineNo)) {
    //     item.isVisible = checkStatus;
    //   }
    // });

    insertLines(notesRenderValue);
  };

  const prepareCheckBoxLineNos = (column2Value) => {};

  const customRowDelete = (heading, line, columnNo) => {
    let deleteRow = false;

    if (line.lineType == 5) {
      let selectedDeleteRowObj = {
        heading: heading,
        line: line,
      };
      setSelectedDeleteRow(selectedDeleteRowObj);
      setConfirmToastVisible(true);
    } else {
      customRowDeleteFunc(heading, line, columnNo);
    }
    /*
    - if line.lineType == 5
      - show conform dialog box
        -if Yes,
          - filter the linesArrayFilteredByHeader for those who has parentLine as line.lineNo
          - remove the matches lines from linesArrayFilteredByHeader
          - close the confirm dialog box
        -else
          - deleteRow = false
          - close the confirm dialog box
     */
  };
  const customRowDeleteFunc = (heading, line, columnNo) => {
    let linesArrayFilteredByHeader = notesRenderValue[heading];
    let lineObjArrayFilteredByLineNo = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo == line.lineNo
    );

    let lineSum = linesArrayFilteredByHeader.filter(
      (obj) =>
        (obj.sumLineNos != "" && obj.parentLineNo == line.parentLineNo) ||
        obj.lineLevel == 5 ||
        (obj.sumLineNos != "" && obj.line == line.parentLineNo)
    );

    //lineLevel 5

    lineSum &&
      lineSum.length &&
      lineSum.map((i, idx) => {
        if (i.sumLineNos !== undefined) {
          let rangeArray = [];

          if (i.lineLevel == 5) {
            let rangeStringArray = i.sumLineNos.split(",");
            rangeArray = rangeStringArray.map((item) => parseInt(item));
          } else {
            let lineNoRange = i.sumLineNos.split("-");
            let start = parseInt(lineNoRange[0]);
            let end = parseInt(lineNoRange[1]);

            for (let j = start; j <= end; j += 1000) {
              rangeArray.push(j);
            }
          }

          let linesToSum = linesArrayFilteredByHeader.filter((obj) =>
            rangeArray.includes(obj.lineNo)
          );

          let totalValueForLineSum = {
            column2: 0,
            column3: 0,
          };

          i.column2 = "";
          i.column3 = "";

          linesToSum.forEach((item) => {
            totalValueForLineSum.column2 += item.column2 || 0;
            totalValueForLineSum.column3 += item.column3 || 0;
          });

          // i.column2 = totalValueForLineSum.column2;
          // i.column3 = totalValueForLineSum.column3;

          // if (columnNo == 2) {
          //   totalValueForLineSum.column2 -= line.column2;
          //   i.column2 = totalValueForLineSum.column2;
          // } else {
          //   totalValueForLineSum.column3 -= line.column3;
          //   i.column3 = totalValueForLineSum.column3;
          // }

          if (i.lineLevel != 5) {
            totalValueForLineSum.column2 -= line.column2;
            totalValueForLineSum.column3 -= line.column3;
          }

          i.column2 = totalValueForLineSum.column2;
          i.column3 = totalValueForLineSum.column3;
        }
      });

    setRender(!render);

    linesArrayFilteredByHeader = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo != line.lineNo
    );

    notesRenderValue[heading] = linesArrayFilteredByHeader;

    insertLines(notesRenderValue);
  };

  const custColumnDeleteAccept = () => {
    // deleteCustomColumnLogic(
    //   selectedDeleteRow.columnsToDeleteFromLines,
    //   selectedDeleteRow.columnNo
    // );

    let columnsToDeleteFromLines = selectedDeleteRow.columnsToDeleteFromLines;
    let cc = notesRenderValue[lastAccordionKey];
    let kk = cc.filter(
      (item) =>
        !columnsToDeleteFromLines.some(
          (bbItem) => bbItem.lineNo === item.lineNo
        )
    );

    notesRenderValue[lastAccordionKey] = kk;

    setNotesRenderValue(notesRenderValue);
    insertLines(notesRenderValue);

    // toast.current.show({
    //   severity: "info",
    //   summary: "Confirmed",
    //   detail: "You have accepted",
    //   life: 3000,
    // });
  };

  const custColumnDeleteReject = () => {
    // toast.current.show({
    //   severity: "warn",
    //   summary: "Rejected",
    //   detail: "You have rejected",
    //   life: 3000,
    // });
  };

  const accept = () => {
    let linesArrayFilteredByHeader =
      notesRenderValue[selectedDeleteRow.heading];

    let linesToDelete = linesArrayFilteredByHeader.filter(
      (item) =>
        item.parentLineNo == selectedDeleteRow.line.lineNo ||
        item.lineNo == selectedDeleteRow.line.lineNo
    );

    linesArrayFilteredByHeader = linesArrayFilteredByHeader.filter(
      (item) => !linesToDelete.some((bbItem) => bbItem.lineNo === item.lineNo)
    );

    /*
    - check is there any sub header present for the selected header (lineType==5). If not remove the
    entire header
    */
    let subHeaderLinesFromLinesArray = linesArrayFilteredByHeader.filter(
      (item) =>
        item.financialYearHeading == selectedDeleteRow.line.financialYearHeading
    );

    let checkSubLinesPresent = subHeaderLinesFromLinesArray.filter(
      (item) => item.lineType == 5
    );

    if (checkSubLinesPresent.length == 0) {
      linesArrayFilteredByHeader = linesArrayFilteredByHeader.filter(
        (item) =>
          !subHeaderLinesFromLinesArray.some(
            (bbItem) => bbItem.lineNo === item.lineNo && bbItem.lineLevel !== 1
          )
      );
    }

    notesRenderValue[selectedDeleteRow.heading] = linesArrayFilteredByHeader;
    customRowDeleteFunc(selectedDeleteRow.heading, selectedDeleteRow.line);

    // toast.current.show({
    //   severity: "info",
    //   summary: "Confirmed",
    //   detail: "You have accepted",
    //   life: 3000,
    // });
  };

  const reject = () => {
    // toast.current.show({
    //   severity: "warn",
    //   summary: "Rejected",
    //   detail: "You have rejected",
    //   life: 3000,
    // });
  };

  const deleteSection = () => {
    console.log(
      selectedDeleteRow,
      "??>",
      selectedDeleteRow != null && selectedDeleteRow.columnsToDeleteFromLines
    );
    return (
      <div>
        Do you want to delete{" "}
        <b>
          {selectedDeleteRow != null &&
            selectedDeleteRow.columnsToDeleteFromLines != null &&
            selectedDeleteRow.columnsToDeleteFromLines[0].column1}
        </b>{" "}
        section ?
      </div>
    );
  };

  return (
    <div>
      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps={true}
        disableScrolling={false}
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <Toast ref={toast} />

      <ConfirmDialog
        group="declarative"
        visible={confirmToastVisible}
        onHide={() => setConfirmToastVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />

      <ConfirmDialog
        group="declarative"
        visible={colConfirmToastVisible}
        onHide={() => setColConfirmToastVisible(false)}
        // message="Are you sure you want to proceed 2?"
        message={() => deleteSection()}
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={custColumnDeleteAccept}
        reject={custColumnDeleteReject}
      />

      <NavBarHeader callBack={callback} isAppPortal={true} isHomePage={false} />

      <div className="representative-sidebar-div">
        <Sidebar
          children="Notes"
          sideBarStatus={(e) => sideBarStatusFnc(e)}
          sideBarCallback={(e) => sideBarCallback(e)}
          tourStatus={tourStatus}
        />
      </div>

      <div className={sideBarStatus ? "ISSideBarOpen" : "ISSideBarClose"}>
        <Row className="ISParentRow">
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ width: "100%" }}>
            {notesRenderValue != "" && (
              <Accordion multiple activeIndex={activeIndex}>
                {Object.keys(notesRenderValue).map((heading, idx) => {
                  if (heading != "financialYearList") {
                    let headerLines = notesRenderValue[heading];
                    let tabHeader = headerLines[0];
                    let currentSubHeader = "";

                    return (
                      <AccordionTab
                        header={accordionHeader(headerLines, heading)}
                        className="IS-Accordion-Tab"
                      >
                        {headerLines &&
                          headerLines.length &&
                          headerLines.map((line, lineIdx) => {
                            if (lineIdx > 0) {
                              return (
                                <div>
                                  {line.header &&
                                    (line.lineLevel == 2 ||
                                      line.lineLevel == 3) &&
                                    ((currentSubHeader =
                                      line.isSubHeader == undefined
                                        ? line
                                        : currentSubHeader),
                                    (
                                      <div className="notesHeaderLine">
                                        {lineIdx > 1 &&
                                          line.lineLevel == 2 &&
                                          !line.default && (
                                            <div className="hrLine" />
                                          )}
                                        <Row>
                                          <Col
                                            xl={3}
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                          >
                                            <div
                                              className={
                                                line.isSubHeader != undefined &&
                                                "lineHeader"
                                              }
                                            >
                                              {line.name}
                                            </div>
                                          </Col>

                                          {line.templateColumn2 ==
                                          "FromToDate" ? (
                                            <>
                                              <>
                                                {line.column2 != "" && (
                                                  <Col
                                                    xl={3}
                                                    lg={3}
                                                    md={3}
                                                    sm={3}
                                                    xs={3}
                                                  >
                                                    <InputMask
                                                      value={line.column2}
                                                      mask="9999-99-99 - 9999-99-99"
                                                      placeholder="YYYY-DD-MM - YYYY-DD-MM"
                                                      slotChar="yyyy-dd-mm - yyyy-dd-mm"
                                                      className="notesFyInputMask"
                                                      disabled={true}
                                                    />
                                                  </Col>
                                                )}

                                                {line.column3 != "" &&
                                                  dynamicFinancialYearInputMask(
                                                    line,
                                                    3,
                                                    headerLines,
                                                    heading,
                                                    idx + 1,
                                                    lineIdx
                                                  )}

                                                {/* {line.column4 != "" &&
                                                  dynamicFinancialYearInputMask(
                                                    line,
                                                    4,
                                                    headerLines,
                                                    heading,
                                                     idx+1,
                                                     lineIdx
                                                    
                                                  )}

                                                {line.column5 != "" &&
                                                  dynamicFinancialYearInputMask(
                                                    line,
                                                    5,
                                                    headerLines,
                                                    heading,
                                                     idx+1,
                                                     lineIdx
                                                    
                                                  )} */}
                                              </>
                                            </>
                                          ) : line.templateColumn2 !=
                                              undefined &&
                                            line.templateColumn2.includes(
                                              "CheckBox"
                                            ) ? (
                                            <Checkbox
                                              onChange={(e) =>
                                                checkBoxOnClick(
                                                  e,
                                                  line,
                                                  heading
                                                )
                                              }
                                              checked={line.isCheckBox}
                                              className="notesCheckBox"
                                            ></Checkbox>
                                          ) : (
                                            <>
                                              {line.column2 != "" &&
                                                line.lineLevel == 2 &&
                                                dynamicFinancialYearInputMask(
                                                  line,
                                                  2,
                                                  headerLines,
                                                  heading,
                                                  idx + 1,
                                                  lineIdx
                                                )}

                                              {line.column3 != "" &&
                                                line.lineLevel == 2 &&
                                                dynamicFinancialYearInputMask(
                                                  line,
                                                  3,
                                                  headerLines,
                                                  heading,
                                                  idx + 1,
                                                  lineIdx
                                                )}

                                              {/* {line.column4 != "" &&
                                                line.lineLevel == 2 &&
                                                dynamicFinancialYearInputMask(
                                                  line,
                                                  4,
                                                  headerLines,
                                                  heading,
                                                   idx+1,
                                                   lineIdx
                                                )}

                                              {line.column5 != "" &&
                                                line.lineLevel == 2 &&
                                                dynamicFinancialYearInputMask(
                                                  line,
                                                  5,
                                                  headerLines,
                                                  heading,
                                                   idx+1,
                                                   lineIdx
                                                )} */}
                                            </>
                                          )}

                                          {line.lineType == 5 && (
                                            <>
                                              <>
                                                {currentSubHeader.column2 !=
                                                  "" &&
                                                  dynamicFinancialYearInputNumber(
                                                    heading,
                                                    line,
                                                    2
                                                  )}

                                                {currentSubHeader.column3 !=
                                                  "" &&
                                                  dynamicFinancialYearInputNumber(
                                                    heading,
                                                    line,
                                                    3
                                                  )}

                                                {/* {currentSubHeader.column4 !=
                                                  "" &&
                                                  dynamicFinancialYearInputNumber(
                                                    headerLines,
                                                    line,
                                                    4
                                                  )}

                                                {currentSubHeader.column5 !=
                                                  "" &&
                                                  dynamicFinancialYearInputNumber(
                                                    headerLines,
                                                    line,
                                                    5
                                                  )} */}
                                              </>
                                            </>
                                          )}
                                        </Row>
                                      </div>
                                    ))}

                                  {/* Textarea Line*/}
                                  {line.lineLevel == 6 && (
                                    <div className="notesHeaderLine">
                                      <InputTextarea
                                        value={
                                          disableLineNos.includes(line.lineNo)
                                            ? ""
                                            : line.column1
                                        }
                                        rows={3}
                                        cols={30}
                                        autoResize={true}
                                        className="notesTextArea"
                                        onChange={(e) =>
                                          textAreaOnChange(
                                            heading,
                                            line,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) => textAreaOnBlur(e)}
                                        disabled={disableLineNos.includes(
                                          line.lineNo
                                        )}
                                      />

                                      {/* {line.column2.includes("CheckBox") && (
                                        <>CC</>
                                      )} */}
                                    </div>
                                  )}

                                  {/* Non Dynamic Column Line*/}
                                  {line.lineLevel == 7 && (
                                    <div className="notesNonDynamicColumnsLine">
                                      <Row>
                                        <Col className="d-flex align-items-center justify-content-start">
                                          <div>
                                            {line.isCustomLine != undefined &&
                                            line.isCustomLine ? (
                                              <>
                                                <Autocomplete
                                                  getItemValue={(item) =>
                                                    item.label
                                                  }
                                                  disabled={disableLineNos.includes(
                                                    line.lineNo
                                                  )}
                                                  items={
                                                    items != null &&
                                                    items.filter((item) =>
                                                      item.label
                                                        .toLowerCase()
                                                        .includes(
                                                          line.column1.toLowerCase()
                                                        )
                                                    )
                                                  }
                                                  renderItem={(
                                                    item,
                                                    isHighlighted
                                                  ) => (
                                                    <div
                                                      key={item.id}
                                                      style={{
                                                        background:
                                                          isHighlighted
                                                            ? "lightgray"
                                                            : "white",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                        borderBottom:
                                                          "1px solid #ddd",
                                                      }}
                                                      className="suggestionDiv"
                                                    >
                                                      {highlightMatchedText(
                                                        item.label,
                                                        item.column1 !=
                                                          undefined
                                                          ? item.column1
                                                          : ""
                                                      )}
                                                    </div>
                                                  )}
                                                  value={line.column1}
                                                  onSelect={(e) => {
                                                    handleSelect(
                                                      e,
                                                      line,
                                                      heading
                                                    );
                                                  }}
                                                  //   onChange={(e) => {
                                                  //     this.customOnChange(
                                                  //       e.target.value,
                                                  //       ISTemplateFieldOutsideMap,
                                                  //       heading
                                                  //     );
                                                  //   }}
                                                  renderInput={(props) => (
                                                    <textarea
                                                      {...props}
                                                      className="auto-resize-textareaa"
                                                    />
                                                  )}
                                                  inputProps={{
                                                    // ref: inputRef,
                                                    // onBlur: (e) =>
                                                    //   this.validateForm(
                                                    //     e,
                                                    //     heading,
                                                    //     idx,
                                                    //     ISTemplateFieldOutsideMap
                                                    //   ),

                                                    // disabled:
                                                    //   readOnly.values && true,

                                                    placeholder:
                                                      TranslationFile
                                                        .EnterYourDescriptionText[
                                                        userSelectedLanguage
                                                      ],

                                                    style: {
                                                      outline: "none",
                                                      width: "400px",
                                                      whiteSpace: "pre-wrap", // Enable wrapping for long words
                                                      wordWrap: "break-word", // Allow word breaking
                                                      overflowWrap:
                                                        "break-word", // For newer browsers
                                                      resize: "none", // Prevent resizing of textarea
                                                      minHeight: "40px", // Minimum height to start
                                                      lineHeight: "1.5", // Increase line height for better readability
                                                      padding: "5px",
                                                      borderRadius: "10px",
                                                      borderColor: "#cdcdcd",
                                                      overflowY: "hidden",
                                                      height:
                                                        line.column1.length /
                                                          55 >
                                                        1.0
                                                          ? Math.round(
                                                              line.column1
                                                                .length / 55
                                                            ) +
                                                            1 * 50 +
                                                            8
                                                          : Math.round(
                                                              line.column1
                                                                .length / 55
                                                            ) *
                                                              30 +
                                                            8,
                                                    },
                                                  }}
                                                  renderMenu={(
                                                    items,
                                                    value,
                                                    style
                                                  ) => (
                                                    <div
                                                      style={{
                                                        ...style,
                                                        marginTop: "5px",
                                                        maxHeight: "200px",
                                                        overflowY: "auto",
                                                        width: "360px",
                                                        lineHeight: 2,
                                                        boxShadow:
                                                          "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                      }}
                                                    >
                                                      {items.length > 0 ? (
                                                        items
                                                      ) : (
                                                        <div
                                                          style={{
                                                            padding: "5px",
                                                            color: "gray",
                                                          }}
                                                        >
                                                          No matches found
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                />
                                              </>
                                            ) : (
                                              <>{line.column1}</>
                                            )}
                                          </div>
                                        </Col>
                                        <Col>
                                          <InputNumber
                                            inputId="integeronly"
                                            value={
                                              disableLineNos.includes(
                                                line.lineNo
                                              )
                                                ? ""
                                                : line.column2
                                            }
                                            className="notesNonDynamicColumnsLineInput"
                                            onBlur={(e) =>
                                              staticInputNumberOnBlur(
                                                heading,
                                                line,
                                                e.target.value
                                              )
                                            }
                                            useGrouping={true}
                                            locale="sv-SE"
                                            disabled={disableLineNos.includes(
                                              line.lineNo
                                            )}
                                          />

                                          {!disableLineNos.includes(
                                            line.lineNo
                                          ) && (
                                            <>
                                              <i
                                                id="notesAddNewLineIcon"
                                                className="fa fa-plus"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  addBtnClick(heading, line)
                                                }
                                              />
                                              {line.isCustomLine && (
                                                <i
                                                  id="notesAddNewLineIcon"
                                                  className="fa fa-trash"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    deleteBtnClick(
                                                      heading,
                                                      line
                                                    )
                                                  }
                                                />
                                              )}
                                            </>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  )}

                                  {/* Line*/}
                                  {line.lineLevel == 3 && !line.header && (
                                    <div className="notesLineSumColumnsLine">
                                      <Row>
                                        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                          {/* // className="d-flex align-items-center justify-content-start"> */}
                                          <div
                                            className={
                                              !line.default &&
                                              "notesLineLevel4Lbl"
                                            }
                                          >
                                            {line.column1}
                                          </div>
                                        </Col>

                                        <>
                                          {currentSubHeader.column2 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              2
                                            )}

                                          {currentSubHeader.column3 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              3
                                            )}

                                          {/* {currentSubHeader.column4 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              4
                                            )}

                                          {currentSubHeader.column5 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              5
                                            )} */}
                                        </>
                                      </Row>
                                    </div>
                                  )}

                                  {/* Line*/}
                                  {line.lineLevel == 4 && !line.header && (
                                    <div className="notesNonDynamicColumnsLine">
                                      <Row>
                                        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                          {/* className="d-flex align-items-center justify-content-start"> */}
                                          <div className="notesLineLevel4Lbl">
                                            {line.column1}
                                          </div>
                                        </Col>

                                        <>
                                          {currentSubHeader.column2 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              2
                                            )}

                                          {currentSubHeader.column3 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              3
                                            )}

                                          {/* {currentSubHeader.column4 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              4
                                            )}

                                          {currentSubHeader.column5 != "" &&
                                            dynamicFinancialYearInputNumber(
                                              heading,
                                              line,
                                              5
                                            )} */}
                                        </>
                                      </Row>
                                    </div>
                                  )}

                                  {/* //Redovisat Line Sum */}
                                  {line.lineLevel == 5 && (
                                    <div className="notesHeaderLine">
                                      <Row>
                                        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                          {line.name}
                                        </Col>

                                        <>
                                          <>
                                            {currentSubHeader.column2 != "" &&
                                              dynamicFinancialYearInputNumber(
                                                heading,
                                                line,
                                                2
                                              )}

                                            {currentSubHeader.column3 != "" &&
                                              dynamicFinancialYearInputNumber(
                                                heading,
                                                line,
                                                3
                                              )}

                                            {/* {currentSubHeader.column4 != "" &&
                                              dynamicFinancialYearInputNumber(
                                                heading,
                                                line,
                                                4
                                              )}

                                            {currentSubHeader.column5 != "" &&
                                              dynamicFinancialYearInputNumber(
                                                heading,
                                                line,
                                                5
                                              )} */}
                                          </>
                                        </>
                                      </Row>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          })}
                      </AccordionTab>
                    );
                  }
                })}
              </Accordion>
            )}
          </Col>
        </Row>

        <Button
          label={TranslationFile.notesAddNewNoteBtn[userSelectedLanguage]}
          aria-label={TranslationFile.FooterAnnualReport[userSelectedLanguage]}
          onClick={() => addNewNotesOnClick()}
          severity="success"
          className="notesAddNewNotesBtn"
          icon="pi pi-external-link"
        />
        <center className="BSSaveBtnCenter">
          <Button
            label={TranslationFile.previousBtn[userSelectedLanguage]}
            aria-label={
              TranslationFile.FooterAnnualReport[userSelectedLanguage]
            }
            onClick={() => pageNavigation("balanceSheet")}
            id="annualReportBtn"
            className="BSSaveBtn"
          />
          <Button
            label={TranslationFile.nextBtn[userSelectedLanguage]}
            aria-label={
              TranslationFile.FooterAnnualReport[userSelectedLanguage]
            }
            onClick={() => pageNavigation("managementStatement")}
            id="annualReportBtn"
            className="BSSaveBtn"
          />
        </center>
      </div>
      <ScrolltoTop />
      <Footer_contact_tab
        sideBar={sideBarStatus}
        isCorpPortal={false}
        isHomePage={true}
      />

      <div className="card flex justify-content-center">
        {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setAddNewDialogBoxVisible(true)} /> */}
        <Dialog
          header={TranslationFile.AddNewNotes[userSelectedLanguage]}
          visible={addNewDialogBoxVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!addNewDialogBoxVisible) return;
            setAddNewDialogBoxVisible(false);
            // setSelectedAddNotesHeader(null);
            // setAddNotesSubHeaderDropdown([]);
            // setSelectedAddNotesSubHeader(null);
            // setSelectedAddNotesSubHeaderLines([]);
          }}
        >
          {/* <p className="m-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p> */}
          <div>
            <div>
              {/* <label className="notesDialogHeadingLbl">
                {TranslationFile.NoteTypeText[userSelectedLanguage]}
              </label>
              <div className="notesDialogNoteTypeDiv">
                UPPLYSNINGAR TILL BALANSRÄKNINGEN
              </div> */}

              <Row>
                <Col>
                  <b>{TranslationFile.NoteTypeText[userSelectedLanguage]}</b>
                </Col>
                <Col>UPPLYSNINGAR TILL BALANSRÄKNINGEN</Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <b>
                    {TranslationFile.HeadingTextSmallCase[userSelectedLanguage]}
                  </b>
                </Col>
                <Col>
                  <Dropdown
                    value={selectedAddNotesHeader}
                    onChange={(e) => NewNotesHeaderOnSelect(e)}
                    options={addNotesHeaderDropdown}
                    optionLabel="name"
                    placeholder="Select a Header"
                    className="addNewNotesHeadingDropdown"
                  />
                </Col>
              </Row>

              <br />

              {addNotesSubHeaderDropdown.length > 0 && (
                <Row>
                  <Col>
                    <b>
                      {
                        TranslationFile.SubHeadingTextSmallCase[
                          userSelectedLanguage
                        ]
                      }
                    </b>
                  </Col>
                  <Col>
                    <Dropdown
                      value={selectedAddNotesSubHeader}
                      onChange={(e) => NewNotesSubHeaderOnSelect(e)}
                      options={addNotesSubHeaderDropdown}
                      optionLabel="name"
                      placeholder="Select a Sub Header"
                      className="addNewNotesHeadingDropdown"
                    />
                  </Col>
                </Row>
              )}

              <br />

              {selectedAddNotesSubHeaderLines.length > 0 && (
                <div>
                  <hr />
                  <div>
                    <b>Lines</b>
                  </div>
                  <div>
                    {selectedAddNotesSubHeaderLines.map((i, idx) => {
                      return (
                        <div className="subHeaderLinesDiv">
                          {i.lineType == 3 && (
                            <>
                              <label className="addNewNotesDialogBoxLineSUmLbl">
                                Line Sum
                              </label>
                              <br />
                            </>
                          )}
                          <InputText
                            value={i.column1}
                            className="subHeaderLinesInputText"
                            disabled
                            placeholder={
                              i.lineType == 2 &&
                              i.column1 == "" &&
                              "Enter you text..."
                            }
                          />

                          {i.lineType != 3 && (
                            <i
                              id="notesSubHeaderLinesDelete"
                              className="fa fa-times-circle"
                              aria-hidden="true"
                              title="Remove Header"
                              onClick={() => deleteDialogBoxLines(i)}
                            ></i>
                          )}

                          {idx == selectedAddNotesSubHeaderLines.length - 1 && (
                            <>
                              <center>
                                <Button
                                  label={
                                    TranslationFile.add[userSelectedLanguage]
                                  }
                                  aria-label={
                                    TranslationFile.FooterAnnualReport[
                                      userSelectedLanguage
                                    ]
                                  }
                                  severity="success"
                                  className="notesAddBtn"
                                  icon="pi pi-check"
                                  onClick={() => addNotesBtnOnClick()}
                                />
                              </center>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
